import styled from 'styled-components'
import Switch from '@material-ui/core/Switch';

const StyledSwitch = styled(Switch)`
    .MuiSwitch-track {
        height: 20px;
        width: 40px;
        border-radius: 10px;
        background: #a5a5a5;
        opacity: 1;
    }
    .MuiSwitch-thumb {
        width: 16px;
        height: 16px;
        background: #FDFEFE;
    }
    .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
        background: #26C49A;
        opacity: 1;
    }
    .MuiSwitch-switchBase {
        left: 5px;
        top: 5px;
    }
    .MuiSwitch-colorPrimary.Mui-checked {
        top: 5px;
        left: 0;
    }
`

export {StyledSwitch}