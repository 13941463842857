import styled from 'styled-components'
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';

const StyledTableHead = styled(TableHead)`
    background: #117D96;
    border-radius: 40px 40px 0px 0px;
`

const StyledTableCell = styled(TableCell)`
    background: none;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    min-width: 150px;
    &:hover span {
        color: #FFFFFF;
    }
`

export {StyledTableHead, StyledTableCell}