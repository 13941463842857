import { makeStyles, useTheme, } from '@material-ui/core/styles';
import BarChart from './barChart'
import * as StatsRouter from '../routing/stats.json'
import { useEffect, useState } from 'react';
import {http} from '../helpers/http'

const colors = [
  {
    primary: '#FD6585',
    secondary: '#FD658510',
  },
  {
    primary: '#51C0BF',
    secondary: '#51C0BF10',
  },
  {
    primary: '#3DA3E8',
    secondary: '#3DA3E810',
  },
  {
    primary: '#FECD60',
    secondary: '#FECD6010',
  },
  {
    primary: '#72FFD5',
    secondary: '#72FFD510',
  },
  {
    primary: '#996CFB',
    secondary: '#996CFB10',
  },
  {
    primary: '#2A343F',
    secondary: '#2A343F10',
  },
  {
    primary: '#007094',
    secondary: '#00709410',
  },
  {
    primary: '#BF2A72',
    secondary: '#BF2A7210',
  }
]

const useStyles = makeStyles((theme)=>({
    head: {
      backgroundColor: "#3f51b54d",
      color:"black",
    },
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 440,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    addButton : {
    margin : '20px'
    },
    header : {
      backgroundColor : 'white',
      flex: 1,
      padding : '5px',
      marginBottom : '30px',
      borderRadius : '5px'
    },
    headerText : {
      fontWeight : 'bold',
      fontSize : '20px'
    },
    content : {
      display : 'flex',
      gap : '20px',
      flex : 1,
      flexWrap : 'wrap',
      justifyContent : 'center'
    },
    card : {
      flex : 1
    }
}));

export default function Stats() {

  const [loading, setLoading] = useState(true)
  const [calls, setcalls] = useState([])
  const [sms, setsms] = useState([])  
  const [totalprice, settotalprice] = useState([])
  const [pfaxminutes, setpfaxminutes] = useState([])
  const [phonenumbers, setphonenumbers] = useState([])
  const [recordings, setrecordings] = useState([])
  const [pfaxpages, setpfaxpages] = useState([])
  const [transcriptions, settranscriptions] = useState([])
  const [pv, setpv] = useState([])

  const fetchData = async(category) =>{
      const url = `${process.env.REACT_APP_API_BASE_URL}${StatsRouter.USAGE}`
      const method = 'POST'
      const body = {
        category
      }
      const headers = {
          Authorization : `Bearer ${localStorage.getItem('token')}`
      }
      let res = await http({url , method, headers, body})

      if(res?.response?.success){
        setLoading(false)
        switch(category) {
          case 'calls': {
            var dataCalls = res?.response?.data
            dataCalls.datasets[0].backgroundColor = colors[0].primary;
            dataCalls.datasets[0].borderRadius = 8
            setcalls(dataCalls)
            break;
          }
          case 'sms': {
            var dataSMS = res?.response?.data
            dataSMS.datasets[0].backgroundColor = colors[1].primary;
            dataSMS.datasets[0].borderRadius = 8
            setsms(dataSMS)
            break;
          }
          case 'totalprice': {
            var dataTotalPrice = res?.response?.data
            dataTotalPrice.datasets[0].backgroundColor = colors[2].primary;
            dataTotalPrice.datasets[0].borderRadius = 8
            settotalprice(dataTotalPrice)
            break;
          }
          case 'pfax-minutes': {
            var dataPFaxMin = res?.response?.data
            dataPFaxMin.datasets[0].backgroundColor = colors[3].primary;
            dataPFaxMin.datasets[0].borderRadius = 8
            setpfaxminutes(dataPFaxMin)
            break;
          }
          case 'phonenumbers': {
            var dataPhoneNumbers = res?.response?.data
            dataPhoneNumbers.datasets[0].backgroundColor = colors[4].primary;
            dataPhoneNumbers.datasets[0].borderRadius = 8
            setphonenumbers(dataPhoneNumbers)
            break;
          }
          case 'recordings': {
            var dataRecordings = res?.response?.data
            dataRecordings.datasets[0].backgroundColor = colors[5].primary;
            dataRecordings.datasets[0].borderRadius = 8
            setrecordings(dataRecordings)
            break;
          }
          case 'pfax-pages': {
            var dataPFaxPages = res?.response?.data
            dataPFaxPages.datasets[0].backgroundColor = colors[6].primary;
            dataPFaxPages.datasets[0].borderRadius = 8
            setpfaxpages(dataPFaxPages)
            break;
          }
          case 'transcriptions': {
            var dataTranscriptions = res?.response?.data
            dataTranscriptions.datasets[0].backgroundColor = colors[7].primary;
            dataTranscriptions.datasets[0].borderRadius = 8
            settranscriptions(dataTranscriptions)
            break;
          }
          case 'pv': {
            var dataPV = res?.response?.data
            dataPV.datasets[0].backgroundColor = colors[8].primary;
            dataPV.datasets[0].borderRadius = 8
            setpv(dataPV)
            break;
          }
          default:
            return null
      }
    }
  }
  
  useEffect(() => {
    let wantedStats= ['calls', 'sms', 'totalprice', 'pfax-minutes', 'phonenumbers','recordings','pfax-pages', 'transcriptions', 'pv']
    for(let i=0; i < wantedStats.length; i++){
      fetchData(wantedStats[i])
    }
  }, [])

    const classes = useStyles();

    return (
    < >
      {/* <div className={classes.header}>
          <p className={classes.headerText} >
          Twillio
          </p>
      </div> */}
          <div className={classes.content}>
          {
            calls?.datasets && 
            <BarChart 
              title={'Calls'} 
              data={calls || []} 
              className={classes.card}
              color={colors[0]}
            />
          }
          {
              sms?.datasets && 
              <BarChart 
                title={'Sms'} 
                data={sms || []} 
                className={classes.card} 
                color={colors[1]} 
              />
          }
          {
              totalprice?.datasets && 
                <BarChart 
                  title={'Total'} 
                  data={totalprice || []} 
                  className={classes.card} 
                  color={colors[2]}
                />
          }
          {
              phonenumbers?.datasets && 
                <BarChart 
                  title={'Phone numbers'} 
                  data={phonenumbers || []} 
                  className={classes.card} color={colors[4]}
                />
          }
          {
              recordings?.datasets && 
                <BarChart 
                  title={'Recordings'} 
                  data={recordings || []} 
                  className={classes.card} 
                  color={colors[5]}
                />
          }
          {
              transcriptions?.datasets && 
                <BarChart 
                  title={'Transcriptions'} 
                  data={transcriptions || []} 
                  className={classes.card} 
                  color={colors[7]}
                />
          }
          {
              pv?.datasets && 
                <BarChart 
                  title={'Programmable Video'} 
                  data={pv || []} 
                  className={classes.card} 
                  color={colors[8]}
                />
          }
          {
              pfaxpages?.datasets && 
                <BarChart 
                  title={'Pfax pages'} 
                  data={pfaxpages || []} 
                  className={classes.card} 
                  color={colors[6]}
                />
          }
          {
              pfaxminutes?.datasets && 
                <BarChart 
                  title={'Pfax minutes'} 
                  data={pfaxminutes || []} 
                  className={classes.card} 
                  color={colors[3]}
                />
          }
          </div>
    </>
    )
}
