import { createGlobalStyle } from 'styled-components';
export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }
`;
export const lightTheme = {
  body: '#FEFFFE',
  text: '#121620',
  shadow: 'rgba(224, 217, 255, 0.4)',
  body2: '#FFFFFF',
  text2 : '#1c1c1c',
  hover: '#f1f1f1',
  green: '#26C49A',
  grey: '#C4C4C41A',
};
export const darkTheme = {
  body: '#121620',
  text: '#f1f1f1',
  shadow: '#00000050',
  body2: '#031a3b',
  text2 : '#f1f1f1',
  hover: '#121620',
  green: '#26C49A',
  grey: '#C4C4C41A'
};