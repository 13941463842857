import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { AuthContext } from "../contexts/auth.context";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import SyncIcon from "@material-ui/icons/Sync";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import SyncProblemIcon from "@material-ui/icons/SyncProblem";
import getPermissions from "../permissions.ts";
import Backdrop from "@material-ui/core/Backdrop";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { FiltersContext } from "../contexts/filters.context";
import { CircularProgress } from '@mui/material';
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { http } from "../helpers/http";
import Snackbar from "../components/snackbar";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import AgencyRouter from "../routing/agency.json";
import AgenciesActions from "./agenciesActions";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Divider from '@mui/material/Divider';
import CloseIcon from '../assets/images/close-modal.svg'

import {
  syncAgencyUserAccess,
  syncAgency,
  createSubUser,
  createSubLocation,
  createMailreach,
} from "../functions/index";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { StyledTableCell, StyledTableHead } from "./StyledTableHeader";
import { StyledTableBodyCell, StyledTableRow } from "./StyledTableBody";
import StyledPagination from "./StyledPadination";
import { StyledCheckbox } from "./StyledCheckbox";
import FilterIcon from '../assets/images/filter-icon.svg'
import { StyledDialogContent, StyledModal } from "./modals/StyledModal";
import Title from "./Title";
import EnvelopIcon from '../assets/images/envelope-icon.svg'
import AddUserIcon from '../assets/images/user-plus-icon.svg'
import SquarPlusIcon from '../assets/images/square-plus-icon.svg'
import ArrowsRotateIcon from '../assets/images/arrows-rotate-icon.svg'
import StyledInput from "./StyledInput";
import StyledSelect from "./StyledSelect";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import styled, {useTheme} from 'styled-components'

const StyledAccordion = styled(Accordion)`
  box-shadow: none !important;
  background: transparent !important;
  &:before {
    display: none;
  }
  position: relative;
`

const StyledAccordionSummary = styled(AccordionSummary)`
  border: none !important;
  .header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .Mui-expanded {
    margin-top: -10px;
  }
`

const Badge = styled.div`
    padding: 5px 10px;
    background: #26C49A20;
    color: #26C49A;
    font-size: 12px;
    font-weight: 600;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 20px;
`

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "main	",
    numeric: false,
    disablePadding: true,
    label: "main",
    align: "center",
  },
  {
    id: "Domain	",
    numeric: true,
    disablePadding: false,
    label: "Domain	",
    align: "center",
  },
  {
    id: "Full name",
    numeric: true,
    disablePadding: false,
    label: "Full name",
    align: "center",
  },
  { id: "Email", numeric: true, disablePadding: false, label: "Email" },
  { id: "Phone", numeric: true, disablePadding: false, label: "Phone" },
  {
    id: "Contact id	",
    numeric: true,
    disablePadding: false,
    label: "Contact id	",
  },
  { id: "tags", numeric: false, disablePadding: false, label: "Tags" },
  { id: "status", numeric: false, disablePadding: false, label: "status" },
  { id: "Actions", numeric: true, disablePadding: false, label: "Actions" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <StyledTableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            icon={<StyledCheckbox checked={false} />}
            checkedIcon={<StyledCheckbox checked={true} />}
          />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: '#26C49A1A',
          borderRadius: 10,
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  title: {
    flex: "1 1 100%",
    fontFamily: 'Rubik',
    color: '#26C49A',
    fontWeight: '600'
  },
  icon: {
    width: 22
  }
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, selected, setisLoading, setsnackbarstatuss } = props;

  let authContext = useContext(AuthContext);
  const permissions = getPermissions();

  const { canDo } = authContext;

  if(numSelected > 0){
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>

        <>
          {canDo(permissions.ADD_MAIL_REACH) && (
            <BootstrapTooltip title="Add to mail reach">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() =>
                  createMailreach(selected, setsnackbarstatuss, setisLoading)
                }
              >
                <img className={classes.icon} src={EnvelopIcon} alt='' />
              </IconButton>
            </BootstrapTooltip>
          )}

          {canDo(permissions.CREATE_SUB_USER) && (
            <BootstrapTooltip title="Create sub user">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() =>
                  createSubUser(selected, setsnackbarstatuss, setisLoading)
                }
              >
                <img className={classes.icon} src={AddUserIcon} alt='' />
              </IconButton>
            </BootstrapTooltip>
          )}

          {canDo(permissions.CREATE_SUB_LOCATION) && (
            <BootstrapTooltip title="Create sub location">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() =>
                  createSubLocation(selected, setsnackbarstatuss, setisLoading)
                }
              >
                <img className={classes.icon} src={SquarPlusIcon} alt='' />
              </IconButton>
            </BootstrapTooltip>
          )}

          {canDo(permissions.SYNC_USER_ACCESS) && (
            <BootstrapTooltip title="Sync  users access to locations">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() =>
                  syncAgencyUserAccess(
                    selected,
                    setsnackbarstatuss,
                    setisLoading
                  )
                }
              >
                <img className={classes.icon} src={ArrowsRotateIcon} alt='' style={{ transform: 'rotate(90deg)' }} />
              </IconButton>
            </BootstrapTooltip>
          )}
          {canDo(permissions.UPDATE_AGENCY) && (
            <BootstrapTooltip title="Sync with GHL">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() =>
                  syncAgency(selected, setsnackbarstatuss, setisLoading)
                }
              >
                <img className={classes.icon} src={ArrowsRotateIcon} alt='' />
              </IconButton>
            </BootstrapTooltip>
          )}
        </>
      </Toolbar>
    );
  }else {
    return null
  }
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function NgAgenciesDatatable() {
  const styledTheme = useTheme()
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("fullName");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsCount, setRowsCount] = useState(0);

  let initSnackBar = {
    open: false,
    message: null,
    state: "error",
  };
  const [snackbarstatus, setsnackbarstatuss] = useState(initSnackBar);

  const [rows, setrows] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [selectedStates, setselectedStates] = useState([]);
  const [search, setsearch] = useState("");
  const [selectedTag, setselectedTag] = useState(null);
  const [selectedOperator, setselectedOperator] = useState(null);
  const [selectedPlans, setselectedPlans] = useState(null);
  const [numberOfLocations, setnumberOfLocations] = useState(null);
  const [status, setStatus] = useState("All");
  const [openFilters, setOpenFilters] = useState(false);

  let filterContext = useContext(FiltersContext);

    const MenuProps = {
      PaperProps: {
        style: {

          background: styledTheme.body2,
          color: styledTheme.text,
        },
      },
  };

  const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    '& .MuiAutocomplete-paper': {
      background: `${styledTheme.body} !important`,
    }
  },
  filters: {
    display: "flex",
    height: "fit-content",
    // minHeight: "80px",
    justifyContent: 'flex-end',
    width: '100%',
    position: 'relative',
  },
  filterButton: {
    width: 50,
    height: 50,
    background: '#117D96',
    borderRadius: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#26C49A'
    }
  },
  filtersForm: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 10000,
    '& .MuiAutocomplete-paper, & .MuiSelect-select': {
      background: `#C4C4C41A !important`,
      color: `${styledTheme.text} !important`,
      border: 'none !important'
    }
  },
  filtersHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  modalTitle: {
    margin: 0,
    fontFamily: 'Rubik',
    color: styledTheme.text,
    fontWeight: '600',
    fontSize: 18
  },
  pageTitle: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
  },
  container: {
    // maxHeight: 440,
    borderRadius: '20px 20px 0 0',
    background: styledTheme.body2,
    boxShadow: `0px 0px 44px ${styledTheme.shadow}`,
    position: 'relative',
    // overflow: 'visible'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  selectd: {
    backgroundColor: "blue",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

  const plans = [
    "professional",
    "premium"
  ];

  const operators = [
        {
          text : "Equal",
          value : "$eq "
        },
        {
          text : "Less than",
          value : "$lt"
      },
      {
        text : "Less than or equal",
        value : "$lte"
    },
    {
      text : "Greater than",
      value : "$gt"
    },
    {
      text : "Greater than or equal",
      value : "$gte"
    },
  ];

  const tags = [
    "onboarding",
    "subscribed",
    "express account",
    "monthly",
    "dfy",
    "dwy",
    "mm",
    "onboarding-form-complete"
  ];

  const classes = useStyles();
  const { states } = filterContext;

  let searchTimer = null;
  const handleSearchChange = (evt) => {
    const val = evt.target.value;
    clearTimeout(searchTimer);
    searchTimer = setTimeout(() => {
      setsearch(val);
    }, 1000);
  };

  let locationNumbertimer = null;
  const handleLocationsNumberChange = (evt) => {
    const val = evt.target.value;
    clearTimeout(locationNumbertimer);
    searchTimer = setTimeout(() => {
      setnumberOfLocations(val);
    }, 1000);
  };

  useEffect(() => {
    fetchData();
  }, [search, selectedStates, selectedTag, status, selectedPlans, selectedOperator, numberOfLocations, page, rowsPerPage]);

  const fetchData = async () => {
    if (isLoading) return;
    setisLoading(true);
    const url = `${process.env.REACT_APP_API_BASE_URL}${AgencyRouter.ALL_AGENCIES}`;
    const method = "POST";
    const body = {
      filters: {
        states: selectedStates,
        search: search,
        tags: [selectedTag, selectedPlans].filter(item=> item !== null),
        location : {
          operator : selectedOperator?.trim() ?? null,
          number : parseInt(numberOfLocations)?? null
        },
        pageSize: rowsPerPage,
        page: page,
        status,
      },
    };
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    let res = await http({ url, method, headers, body });
    if (res?.response?.success) {
      setrows(res.response.data.records);
      setRowsCount(res.response.data.total || 0)
    }
    // setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})
    setisLoading(false);
    console.log(res);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, _id) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
    
      <Snackbar
        key="xxx"
        open={snackbarstatus?.open}
        message={snackbarstatus?.message}
        state={snackbarstatus?.state}
      ></Snackbar>
 
      <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>

      <div className={classes.pageTitle}>
        <Title
          title={'Agencies'}
        />
        <div className={classes.filters}>
          <div 
            style={{ 
              position: 'absolute', 
              width: '100%', height: '100%', 
              zIndex: 1000,
              right: 80,
              background: 'transparent'
            }}
          />
          <StyledInput
            autoFocus
            style={{
              width: 300,
              // height: "40px",
              // marginRight: "10px",
              // marginTop: "0px",
              position: 'absolute',
              zIndex: 1000,
              right: 80,
              top: openFilters ? 28 : 3,
              transition: '0.2s'
            }}
            id="Search"
            label="Search"
            type="text"
            margin="dense"
            onChange={handleSearchChange}
            variant='outlined'
            inputProps={{
              style: {
                height: 30,
                borderRadius: 15
              }
            }}
          />
          <StyledAccordion>
            <StyledAccordionSummary>
              <div className="header">
                <div onClick={() => setOpenFilters(!openFilters)} className={classes.filterButton}>
                  <img src={FilterIcon} alt='' />
                </div>
              </div>
            </StyledAccordionSummary>
            <AccordionDetails>
              <div className={classes.filtersForm}>
                <Autocomplete
                  id="state-select"
                  style={{
                    width: 300,
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    marginRight: '10px'
                  }}
                  options={states}
                  autoHighlight
                  onChange={(event, value) => {
                    setselectedStates(value ? value?.abbreviation : []);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(option) => (
                    <React.Fragment value={option.abbreviation}>
                      <span>{option.abbreviation} - </span>
                      {option.name}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <StyledInput
                      {...params}
                      label="Choose a state"
                      variant="outlined"
                      margin="dense"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        style: {
                          height: 30,
                          borderRadius: 15
                        }
                      }}
                    />
                  )}
                />
                <Autocomplete
                  id="state-select"
                  style={{
                    width: 300,
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    marginRight: '10px'
                  }}
                  options={tags}
                  autoHighlight
                  onChange={(event, value) => {
                    setselectedTag(value ? value : null);
                  }}
                  getOptionLabel={(option) => option}
                  renderOption={(option) => (
                    <React.Fragment value={option}>
                      <span>{option}</span>
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <StyledInput
                      {...params}
                      label="Choose a tag"
                      variant="outlined"
                      margin="dense"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                        style: {
                          height: 30,
                          borderRadius: 15,
                        }
                      }}
                    />
                  )}
                />
                <FormControl
                  variant="outlined"
                  sx={{ m: 1, minWidth: 120 }}
                  style={{ width: 300, marginTop: "3px" }}
                >
                  <InputLabel id="status-select" style={{ color: styledTheme.text, opacity: 0.7 }}>Status</InputLabel>
                  <StyledSelect
                    id="state-select"
                    labelId="status-select"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    label="status"
                    className={classes.drops}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={"All"}>ALL</MenuItem>
                    <MenuItem value={"payment_failed"}>Payment Failed</MenuItem>
                    <MenuItem value={"canceled"}>Canceled</MenuItem>
                    <MenuItem value={"need_review"}>Need Review</MenuItem>
                  </StyledSelect>
                </FormControl>
                <Autocomplete
                  id="plans-select"
                  style={{
                    width: 300,
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    marginLeft: "10px",
                  }}
                  options={plans}
                  autoHighlight
                  onChange={(event, value) => {
                    setselectedPlans(value ? value : null);
                  }}
                  getOptionLabel={(option) => option}
                  renderOption={(option) => (
                    <React.Fragment value={option}>
                      <span>{option}</span>
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <StyledInput
                      {...params}
                      label="Choose a plan"
                      variant="outlined"
                      margin="dense"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "professional",
                        style: {
                          height: 30,
                          borderRadius: 15
                        }
                      }}
                    />
                  )}
                />
                <Autocomplete
                  id="locations-length"
                  style={{
                    width: 300,
                    // marginTop: "10px",
                    paddingBottom: "0px",
                    marginLeft: "10px",
                  }}
                  options={operators}
                  autoHighlight
                  onChange={(event, value) => {
                    setselectedOperator(value?.value ?? null);
                  }}
                  getOptionLabel={(option) => option.text}
                  renderOption={(option) => (
                    <React.Fragment value={option.value}>
                      <span>{option.text}</span>
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <StyledInput
                      {...params}
                      label="Nbre of locations"
                      variant="outlined"
                      margin="dense"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "Great",
                        style: {
                          height: 30,
                          borderRadius: 15
                        }
                      }}
                    />
                  )}
                />
                <StyledInput
                  autoFocus
                  style={{
                    width: 300,
                    marginLeft: "10px",
                    marginTop: "6px",
                  }}
                  id="number-of-locations"
                  label="Number of locations"
                  type="text"
                  variant="outlined"
                  margin="dense"
                  inputProps={{
                    style: {
                      height: 30,
                      borderRadius: 15
                    }
                  }}
                  onChange={handleLocationsNumberChange}
                />
              </div>
            </AccordionDetails>
          </StyledAccordion>
        </div>
      </div>

        <EnhancedTableToolbar
          numSelected={selected.length}
          selected={selected}
          setisLoading={setisLoading}
          setsnackbarstatuss={setsnackbarstatuss}
        />
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  console.log('refreshed')
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const tags = row?.tags?.split(',')

                  console.log({tags})

                  return (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                    >
                      <StyledTableBodyCell>
                          <Checkbox
                            color="primary"
                            onClick={(event) => handleClick(event, row._id)}
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            icon={<StyledCheckbox checked={false} />}
                            checkedIcon={<StyledCheckbox checked={true} />}
                          />
                      </StyledTableBodyCell>
                      <StyledTableBodyCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.mainLocationId}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell align="left">{row.AgencyDomain}</StyledTableBodyCell>
                      <StyledTableBodyCell align="left">{row.full_name}</StyledTableBodyCell>
                      <StyledTableBodyCell align="left">{row.email}</StyledTableBodyCell>
                      <StyledTableBodyCell align="left">{row.phone}</StyledTableBodyCell>
                      <StyledTableBodyCell align="left">{row.contact_id}</StyledTableBodyCell>
                      <StyledTableBodyCell align="left" style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {
                          tags && tags?.map((value, index) => {
                            return(
                              <Badge key={'tag'+index}>
                                {value}
                              </Badge>
                            )
                          })
                        }
                      </StyledTableBodyCell>
                      <StyledTableBodyCell align="left">
                        {row.need_admin_review
                          ? ` Need Review - Multiple subscription`
                          : row.canceled_at
                          ? `Canceled at ${new Date(
                              row.canceled_at
                            ).toLocaleDateString()}`
                          : row.last_payment_is_failed
                          ? "Payment failed"
                          : "Active"}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell align="left">
                        <AgenciesActions
                          row={row}
                          setisLoading={setisLoading}
                          fetchData={fetchData}
                          setsnackbarstatuss={setsnackbarstatuss}
                        ></AgenciesActions>
                      </StyledTableBodyCell>
                    </StyledTableRow>
                  );
                })}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>

        <StyledPagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          // count={rows.length}
          count={rowsCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </div>
  );
}
