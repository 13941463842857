import Select from '@mui/material/Select';
import styled from 'styled-components'

const StyledSelect = styled(Select)`
background: #C4C4C41A;
height: 50px;
.MuiOutlinedInput-root {
    background: #C4C4C41A;
}
& label.Mui-focused {
  color: ${({theme}) => theme.text};
}
.MuiOutlinedInput-notchedOutline {
    border-color: #C4C4C41A
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #26C49A
}
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #26C49A !important
}
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #C4C4C490
}

.MuiPaper-root {
    background: ${({theme}) => theme.body} !important;
}

label {
    color: ${({theme}) => theme.text} !important;
}

.MuiPaper-root {
    background: ${({theme}) => theme.body} !important;
}
`

export default StyledSelect