import React, { useState, useContext} from 'react'
import '../style/login.css'
import { makeStyles } from '@material-ui/core/styles';
import * as AuthRouter from '../routing/auth.json'
import {http} from '../helpers/http';
import Snackbar from '../components/snackbar';
import {AuthContext} from "../contexts/auth.context";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '../components/Button'
import {Container, Card, Icon, ErrorMessage} from '../style/login'
import EmailIcon from '../assets/images/email-icon.svg'
import LockIcon from '../assets/images/padlock-icon.svg'
import Logo from '../assets/images/leadific.svg'
import LogoWhite from '../assets/images/leadific-white.svg'
import validator from 'validator'
import axios from 'axios'
import { useTheme } from 'styled-components';

require('dotenv').config();

const useStyles = makeStyles((theme)=>({
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 440,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

export default function Login() {
    const styledTheme = useTheme()
    const classes = useStyles();
    let authContext = useContext(AuthContext)
    const [isLoading, setisLoading] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)

    const {
        isAuth,
        auth
        } = authContext
    
    let initCreds = {
        username : '',
        password : ''
    }

    let initSnackBar = {
        open : false, message : null, state : 'error'
    }

    const [creds, setcreds] = useState(initCreds)
    const [snackbarstatus, setsnackbarstatuss] = useState(initSnackBar)

    const handleChange = (e) => {
        var value = e.target.value
        var name = e.target.name
        if(name === 'email'){
            if (validator.isEmail(value)) {
                setEmailError(false)
                setcreds({...creds, username: value})
            } else {
                setEmailError(true)
                setcreds({...creds, username: value})
            }
        }else{
            if(value.length > 5){
                setPasswordError(false)
                setcreds({...creds, password: value})
            }else{
                setPasswordError(true)
                setcreds({...creds, password: value})
            }
        }

    }

    const sendLogin = async() => {

        console.log('clicked 0')

        setisLoading(true)
        setsnackbarstatuss(initSnackBar)

        
        const url = `${process.env.REACT_APP_API_BASE_URL}${AuthRouter.LOGIN}`
        const method = 'POST'
        const body = {
            userName : creds.username,
            password : creds.password
        }

        console.log({creds})
        console.log({body})
        console.log('clicked')

        // axios.post(url, body).then((result) => {
        //     console.log({result})
        // }).catch((error) => {
        //     console.log({error})
        // })

        let res = await http({url , method, body})
        console.log({res})
        if(res?.response?.success === true){
            localStorage.setItem('token', res?.response?.token);
            auth(res?.response?.token)
        }
        setisLoading(false)
        // setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})
    }

    return (
        <Container>
            <Backdrop open={isLoading} className={classes.backdrop}>
                <CircularProgress color="primary" />
            </Backdrop>
            <Card>
                <img src={styledTheme.body === '#FEFFFE' ? Logo : LogoWhite} alt="" className="formLogo"/>
                {/* <form> */}
                    <div className='input-container'>
                        <Icon>
                            <img src={EmailIcon} alt='' />
                        </Icon>
                        <input 
                            placeholder='Enter your email'
                            type="email"
                            name="email"
                            value={creds.username}
                            onChange={(e)=>{handleChange(e)}}
                        />
                        <ErrorMessage>
                            {emailError && 'Enter a valid email'}
                        </ErrorMessage>
                    </div>
                    <div className='input-container'>
                        <Icon>
                            <img src={LockIcon} alt='' />
                        </Icon>
                        <input 
                            placeholder='Enter your password'
                            type="password"
                            name="password"
                            value={creds.password}
                            onChange={(e)=>{handleChange(e)}}
                        />
                         <ErrorMessage>
                            {passwordError && 'Enter a valid password'}
                        </ErrorMessage>
                    </div>
                    <Button 
                        type="button" 
                        disabled={(creds.username === '' || creds.password === '')}
                        width={'200px'}
                        onClick={()=>{sendLogin()}}
                        hover
                    >
                        Log in
                    </Button>
                {/* </form> */}
            </Card>
            <Snackbar open={snackbarstatus.open}  message={snackbarstatus.message} state={snackbarstatus.state}></Snackbar>
        </Container>

    )
}
