import React, { useEffect, useState } from "react";
import * as AdminRouter from "../../routing/agency.json";
import Snackbar from "../snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { http } from "../../helpers/http";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { StyledDialogActions, StyledDialogContent, StyledDialogContentText, StyledDialogTitle, StyledModal } from "./StyledModal";
import Button from "../Button";
import StyledSelect from "../StyledSelect";
import { FormControl, TextField } from "@material-ui/core";
import { Autocomplete, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import StyledInput from "../StyledInput";
import styled from 'styled-components'

const Badge = styled.div`
    padding: 5px 10px;
    min-width: 80px;
    background: #26C49A20;
    color: #26C49A;
    font-size: 12px;
    font-weight: 600;
    margin-right: 5px;
    border-radius: 20px;
    text-align: center;
`

export default function ImportSnapshotModal({
  openImportSnapShotModal,
  hundleopenImportSnapShotModal,
  fetchData,
  agencyId,
}) {
  let initSnackBar = {
    open: false,
    message: null,
    state: "error",
  };

  const [snackbarstatus, setsnackbarstatuss] = useState(initSnackBar);

  const [open, setOpen] = React.useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [selectedSnapshot, setSelectedSnapshot] = useState(null);
  const [snapshotsList, setSnapshotsList] = useState([]);

  useEffect(() => {
    setOpen(openImportSnapShotModal);
  }, [openImportSnapShotModal]);

  const onSubmit = async () => {

    setisLoading(true);

    setsnackbarstatuss(initSnackBar);
    const url = `${process.env.REACT_APP_API_BASE_URL}${AdminRouter.SNAPSHOTS_IMPORT}`;
    const method = "POST";
    const body = {
      snapshot_id: selectedSnapshot.id,
      agency_id: agencyId,
    };
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    let res = await http({ url, method, headers, body });

    setsnackbarstatuss({
      open: true,
      message: res.response?.message,
      state: res.response?.success ? "success" : "error",
    });

    if (res?.response?.success) {
      handleClose();
      fetchData();
    }

    setisLoading(false);
  };

  const getSnapshotsList = async () => {
    const url = `${process.env.REACT_APP_API_BASE_URL}${AdminRouter.GET_SNAPSHOTS_GHL}`;
    const method = "POST";
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    let res = await http({ url, method, headers });
    if(res.status){
      setSnapshotsList(res?.response?.data)
    }
  }

  useEffect(() => {
    getSnapshotsList()
  }, [])
  

  useEffect(() => {
    setOpen(openImportSnapShotModal);
  }, [openImportSnapShotModal]);

  const handleClose = () => {
    hundleopenImportSnapShotModal();
    setOpen(false);
  };

  return(
    <div>
      <StyledModal
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <StyledDialogTitle id="form-dialog-title">Select a snapshot</StyledDialogTitle>
        <StyledDialogContent>
          <DialogContentText style={{ marginBottom: 20 }}>
            Search and select a snapshot to import
          </DialogContentText>
          <Autocomplete
            options={snapshotsList}
            getOptionLabel={option => option.name}
            sx={{ width: 550 }}
            onChange={(event, newValue) => {
                setSelectedSnapshot(newValue)
            }}
            renderInput={params => (
              <StyledInput {...params} label="Snapshots" fullWidth />
            )}
            renderOption={(props, option) => {                    
                return(
                    <div 
                        {...props}
                        style={{ display: 'flex', textAlign: 'left' }}
                    >
                        <span style={{ flex: 1 }}>
                            {option?.name}
                        </span>
                        <Badge>
                            {option?.type}
                        </Badge>
                    </div>
                )
            }}
          />
        </StyledDialogContent>
        <StyledDialogActions>
            <Button 
                onClick={handleClose}
                background={'#FD658520'} 
                color="#FD6585"
                width={'120px'}
                height={'50px'}
                fontSize={'16px'}
            >
                Cancel
            </Button>
            <Button 
                onClick={() => {
                    if(selectedSnapshot){
                        onSubmit()
                    }
                }}
                background="#26C49A1A" 
                width={'120px'}
                height={'50px'}
                color={'#26C49A'}
                fontSize={'16px'}
                opacity={(selectedSnapshot) ? '1' : '0.7'}
            >
                {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress color="inherit" size={24} />
                </div>
                ) : ("Import")}
            </Button>
        </StyledDialogActions>
      </StyledModal>

      <Snackbar 
        key="updateSnapshot" 
        open={snackbarstatus.open}  
        message={snackbarstatus.message} 
        state={snackbarstatus.state}
      />
    </div>
  )
}
