import styled from 'styled-components'
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const StyledTableBodyCell = styled(TableCell)`
    background: none;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding: 10px 20px;
    color: ${({ theme }) => theme.text};
`

const StyledTableRow = styled(TableRow)`
    background: ${({ theme }) => theme.body2};
    :hover {
        background: ${({ theme }) => theme.hover} !important;
    }
`

export {StyledTableBodyCell, StyledTableRow}