import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import * as AdminRouter from '../routing/admin.json'
import {http} from '../helpers/http'
import Snackbar from '../components/snackbar'
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import Backdrop from '@material-ui/core/Backdrop';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinkIcon from '@material-ui/icons/Link';
import {
    Link
} from "react-router-dom";
import CreateAdminModal from './modals/createAdminModal';
import UpdateAdminModal from './modals/updateAdminModal';
import LinkGhlAdminModal from './modals/linkGhlAdminModal';
import {AuthContext} from '../contexts/auth.context'
import getPermissions from '../permissions.ts'
import StyledPagination from './StyledPadination';
import {StyledTableHead, StyledTableCell} from './StyledTableHeader'
import { StyledTableBodyCell, StyledTableRow } from './StyledTableBody';
import { StyledSwitch } from './StyledSwitch';
import Button from './Button';
import EditIcon from '../assets/images/edit-icon.svg'
import LinksIcon from '../assets/images/link-icon.svg'
import {useTheme} from 'styled-components'

const columns = [
  { id: 'id', label: '_Id', minWidth: 170 },
  { id: 'first_name', label: 'first_name', minWidth: 100 },
  { id: 'last_name', label: 'last_name', minWidth: 100 },
  { id: 'email', label: 'email', minWidth: 100 },
  { id: 'isActive', label: 'Activated', minWidth: 100 },
  {
    id: 'Actions',
    label: 'Actions',
    // minWidth: 170,
    // align: 'right',
    format: (value) => value.toFixed(2),
  },
];

export default function AdminsDatatableC(props) {
    const styledTheme = useTheme()
    let initSnackBar = {
      open : false, message : null, state : 'error'
    }

    let authContext = useContext(AuthContext)

    const {
      canDo
    } = authContext
    
    const useStyles = makeStyles((theme)=>({
      head: {
        backgroundColor: "#3f51b54d",
        color:"black",
      },
      root: {
        width: '100%',
      },
      container: {
        // maxHeight: 440,
        borderRadius: '20px 20px 0 0',
        background: styledTheme.body2,
        boxShadow: `0px 0px 44px ${styledTheme.shadow}`,
        position: 'relative',
        // overflow: 'visible'
      },
      action: {
        width: '100%',
        position: 'absolute',
        top: -100,
        padding: '20px 0',
        display: 'flex',
        justifyContent: 'flex-end'
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: styledTheme.text,
      },
      addButton : {
        margin : '20px'
      },
      header : {
        backgroundColor : 'white',
        flex: 1,
        padding : '5px',
        marginBottom : '30px',
        borderRadius : '5px'
      },
      headerText : {
        fontWeight : 'bold',
        fontSize : '20px'
      }
    }));

    const activateAdmin = async(id,isActive) =>{
      setsnackbarstatuss(initSnackBar)
        const url = `${process.env.REACT_APP_API_BASE_URL}${AdminRouter.ACTIVATE_ADMIN}`
        const method = 'POST'
        const headers = {
            Authorization : `Bearer ${localStorage.getItem('token')}`
        }
        const body = {
          id,
          isActive
        }
        let res = await http({url , method, headers, body})
        fetchData()
        setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})
        console.log(res)
    }

    const permissions =  getPermissions()
    const [snackbarstatus, setsnackbarstatuss] = useState(initSnackBar)
    const [isLoading, setisLoading] = useState(false)

    const hundleOpenCreateModel = () => {
        setopenComand(!openComand)
    }

    const hundleOpenUpdateModel = () => {
      setopenUpdateComand(!openUpdateComand)
    }
      
    const hundleopenLinkGhlAdminModal = () => {
      setopenLinkGhlAdminModal(!openLinkGhlAdminModal)
    }
  

    const adminsDataInit = []
    const [adminsData, setadminsData] = useState(adminsDataInit)
    const [openComand, setopenComand] = useState(false)
    const [openUpdateComand, setopenUpdateComand] = useState(false)
    const [openLinkGhlAdminModal, setopenLinkGhlAdminModal] = useState(false)
    const [adminToUpdate, setadminToUpdate] = useState(null)

    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowsCount, setRowsCount] = useState(0);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    useEffect(() => {
      fetchData()
    }, [page, rowsPerPage])

    const fetchData = async() =>{
      setisLoading(true)
      setsnackbarstatuss(initSnackBar)
        const url = `${process.env.REACT_APP_API_BASE_URL}${AdminRouter.ALL_ADMIN}`
        const method = 'POST'
        const body = {
          filters: {
            pageSize: rowsPerPage,
            page: page,
          },
        };
        const headers = {
            Authorization : `Bearer ${localStorage.getItem('token')}`
        }
        let res = await http({url , method, headers, body})
        if(res?.response?.success){
            console.log('admins data',{res})
            setadminsData(res.response.data.records)
            setRowsCount(res.response.data.total || 0)
        }
        setisLoading(false)
        setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})
        console.log(res)
    }

  return (
    <>
      <LinkGhlAdminModal
        openLinkGhlAdminModal={openLinkGhlAdminModal}
        fetchData={fetchData} 
        hundleopenLinkGhlAdminModal={hundleopenLinkGhlAdminModal}
        adminToUpdate={adminToUpdate}
        setadminToUpdate={setadminToUpdate}
      />

      <UpdateAdminModal 
        adminToUpdate={adminToUpdate} 
        fetchData={fetchData} 
        openUpdateComand={openUpdateComand} 
        hundleOpenUpdateModel={hundleOpenUpdateModel} 
        setadminToUpdate={setadminToUpdate}
      />

      <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      
      <CreateAdminModal fetchData={fetchData} openComand={openComand} hundleOpenCreateModel={hundleOpenCreateModel}></CreateAdminModal>

      <div style={{ position: 'relative' }}>
        <div className={classes.action}>
          {
            canDo(permissions.CREATE_ADMIN)  && 
            <Button
              width={'200px'}
              onClick={(e)=>{hundleOpenCreateModel()}}
            >
              Add new admin
            </Button>
          }
        </div>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <StyledTableHead >
              <TableRow >
                {columns.map((column) => {
                  if(column.id === 'Actions'){
                    if(!canDo(permissions.UPDATE_ADMIN) && !canDo(permissions.UPDATE_GHL_ADMIN)){
                      return null
                    }
                  }
                  return (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </StyledTableCell>
                  )
                })}
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {/* {adminsData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => { */}
              {adminsData?.map((row) => {
                return (
                  <StyledTableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                      <StyledTableBodyCell >
                          {row._id}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell >
                          {row.first_name}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell >
                          {row.last_name}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell >
                          {row.email}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell >
                          <StyledSwitch
                            checked={row.isActive}
                            onClick={()=>activateAdmin(row._id, !row.isActive)}
                            color="primary"
                            name="checkedB"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </StyledTableBodyCell>

                        {
                          (canDo(permissions.UPDATE_ADMIN) || canDo(permissions.UPDATE_GHL_ADMIN)) &&
                          <StyledTableBodyCell >
                          {
                            canDo(permissions.UPDATE_ADMIN)  &&   
                              <IconButton  
                                color="primary" 
                                aria-label="upload picture" 
                                component="span"
                                onClick={(e)=>{
                                  setadminToUpdate(row)
                                  hundleOpenUpdateModel()
                                }}
                                style={{
                                  width: 50,
                                  height: 50
                                }}
                              >
                                <img src={EditIcon} alt='' />
                              </IconButton>
                            }

                            {
                            canDo(permissions.UPDATE_GHL_ADMIN)  && 
                              <IconButton 
                                color="primary" 
                                aria-label="upload picture" 
                                component="span"
                                onClick={(e)=>{
                                  setadminToUpdate(row)
                                  hundleopenLinkGhlAdminModal()
                                }}
                                style={{
                                  width: 50,
                                  height: 50
                                }}
                              >
                                <img src={LinksIcon} alt='' />
                              </IconButton>
                              }
                          </StyledTableBodyCell>
                        }

                    {/* {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })} */}
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <StyledPagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        count={rowsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

    <Snackbar key="createAdmin" open={snackbarstatus.open}  message={snackbarstatus.message} state={snackbarstatus.state}></Snackbar>
    </>
  );
}
