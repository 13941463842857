import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import * as AgencyRouter from "../routing/agency.json";
import { http } from "../helpers/http";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import DomainIcon from "@material-ui/icons/Domain";
import DeleteIcon from "@material-ui/icons/Delete";
import ChangeDomainModal from "./modals/changeDomainModal";
import UpgradeUserModal from "./modals/upgradeUserModal";
import DeleteAgencyModal from "./modals/deleteAgencyModal";
import GrantGhlAdminAccessModal from "./modals/ghlAdminAccessModal";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import SyncIcon from "@material-ui/icons/Sync";
import Tooltip from "@material-ui/core/Tooltip";
import { AuthContext } from "../contexts/auth.context";
import { FiltersContext } from "../contexts/filters.context";
import getPermissions from "../permissions.ts";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddAgencyModal from "./modals/addLocationModal";
import UpdateSnapShotModal from "./modals/UpdateSnapShotModal";
import UpdateMainLocationModal from "./modals/updateMainLocationModal";
import UpdateApiKeyModal from "./modals/updateApiKeyModal";

import { Link } from "react-router-dom";

import {
  syncAgencyUserAccess,
  syncAgency,
  createSubUser,
  createSubLocation,
  createMailreach,
} from "../functions/index";
import { Menu } from "@material-ui/core";
import ThreeDotsIcon from '../assets/images/dots-icon.svg'
import ThreeDotsIconWhite from '../assets/images/dots-icon-white.svg'
import AddUserIcon from '../assets/images/user-plus-icon.svg'
import TrendIcon from '../assets/images/arrow-trend-icon.svg'
import InfosIcon from '../assets/images/infos-icon.svg'
import EnvelopIcon from '../assets/images/envelope-icon.svg'
import SquarPlusIcon from '../assets/images/square-plus-icon.svg'
import ArrowsRotateIcon from '../assets/images/arrows-rotate-icon.svg'
import ArrowsRotateIcon2 from '../assets/images/rotate-arrows-icon2.svg'
import TrashIcon from '../assets/images/trash-icon.svg'
import KeysIcon from '../assets/images/key-icon.svg'
import OneIcon from '../assets/images/one-icon.svg'
import AgenciesIcon from '../assets/images/agencies-icon-green.svg'
import EyeIcon from '../assets/images/eye-icon.svg'
import LockIcon from '../assets/images/lock-icon.svg'
import StyledMenuIcon from "./StyledMenuIcon";
import { useTheme } from "styled-components";
import ImportSnapshotModal from "./modals/importSnapshotModal";

export default function AgenciesActions({
  row,
  fetchData,
  setisLoading,
  setsnackbarstatuss,
}) {
  const styledTheme = useTheme()
  let authContext = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const permissions = getPermissions();

  const [openChangeDomainModal, setopenChangeDomainModal] = useState(false);
  const [openDeleteAgencyModal, setopenDeleteAgencyModal] = useState(false);
  const [openAddLocationModal, setopenAddLocationModal] = useState(false);
  const [openMainLocationUpdateModal, setopenMainLocationUpdateModal] =
    useState(false);
    const [openApiKeyUpdateModal, setopenApiKeyUpdateModal] =
    useState(false);
  const [openUpgradeUserModal, setopenUpgradeUserModal] = useState(false);
  const [openUpdateSnapShotModal, setopenUpdateSnapShotModal] = useState(false);
  const [openImportSnapShotModal, setopenImportSnapShotModal] = useState(false);
  const [openGhlGrantAdminAccess, setopenGhlGrantAdminAccess] = useState(false);
  const [contactId, setcontactId] = useState(null);
  const [agencyId, setagencyId] = useState(null);
  const [locations, setlocations] = useState([]);
  const [mainLocation, setmainLocation] = useState("");
  const [apiKey, setApiKey] = useState("");

  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
    item: {
      padding: 5,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      borderRadius: 6,
      '&:hover': {
        backgroundColor: '#00000010'
      },
      '& span': {
        color: styledTheme.text
      }
    },
    dropdownstyle: {
      '& .MuiMenu-paper': {
        backgroundColor: styledTheme.body,
        borderRadius: 10,
        overflow: 'hidden'
      }
    }
  }));

  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
  }

  const classes = useStylesBootstrap();

  const hundleOpenChangeDomainModel = (contact_id) => {
    setcontactId(contact_id);
    setopenChangeDomainModal(!openChangeDomainModal);
  };

  const hundleopenGhlGrantAdminAccess = (agency_id) => {
    setagencyId(agency_id);
    setopenGhlGrantAdminAccess(!openGhlGrantAdminAccess);
  };

  const hundleOpenDeleteAgencyModal = (contact_id, agency_id) => {
    setcontactId(contact_id);
    setagencyId(agency_id);
    setopenDeleteAgencyModal(!openDeleteAgencyModal);
  };

  const hundleOpenAddLocationModal = (agency_id) => {
    setagencyId(agency_id);
    setopenAddLocationModal(!openAddLocationModal);
  };

  const hundleOpenMainLocationUpdateModal = (agency_id, main_location) => {
    setagencyId(agency_id);
    setmainLocation(main_location);
    setopenMainLocationUpdateModal(!openMainLocationUpdateModal);
  };

  const hundleOpenApiKeyUpdateModal = (agency_id, main_location) => {
    setagencyId(agency_id);
    setApiKey(main_location);
    setopenApiKeyUpdateModal(!openApiKeyUpdateModal);
  };

  const hundleOpenUpgradeUserModal = (agency_id, main_location) => {
    setagencyId(agency_id);
    setmainLocation(main_location);
    setopenUpgradeUserModal(!openUpgradeUserModal);
  };

  const hundleopenUpdateSnapShotModal = (
    agency_id,
    main_location,
    locationsData
  ) => {
    setagencyId(agency_id);
    setmainLocation(main_location);
    setopenUpdateSnapShotModal(!openUpdateSnapShotModal);
    setlocations(locationsData);
  };

  const hundleopenImportSnapShotModal = (
    agency_id,
    main_location,
    locationsData
  ) => {
    setagencyId(agency_id);
    setmainLocation(main_location);
    setopenImportSnapShotModal(!openImportSnapShotModal);
    setlocations(locationsData);
  };

  const { canDo } = authContext;

  return(
    <div style={{ display: 'flex' }}>
      <IconButton
        onClick={handleClick}
        style={{
          width: 35,
          height: 35
        }}
      >
        <img src={styledTheme.body === '#FEFFFE' ? ThreeDotsIcon : ThreeDotsIconWhite} alt='' />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        className={classes.dropdownstyle}
      >
        <div
          style={{
            display: 'flex',
            minWidth: 500,
            justifyContent: 'space-around',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>

            {canDo(permissions.UPDATE_AGENCY_DOMAIN) && (
              <BootstrapTooltip title="Update domain">
                <div
                  onClick={() => hundleOpenChangeDomainModel(row.contact_id)}
                  className={classes.item}
                >
                  <StyledMenuIcon 
                    icon={AgenciesIcon}
                  />
                  <span>
                    Update domain
                  </span>
                </div>
              </BootstrapTooltip>
            )}
            {canDo(permissions.READ_AGENCY) && (
              <BootstrapTooltip title="More details">
                <Link to={`/agencies/${row._id}`} style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}>
                  <div
                    className={classes.item}
                  >
                    <StyledMenuIcon 
                      icon={EyeIcon}
                    />
                    <span>
                      More details
                    </span>
                  </div>
                </Link>
              </BootstrapTooltip>
            )}
            {canDo(permissions.GRANT_ADMIN_ACCESS) && (
              <BootstrapTooltip title="GHL admin access">
                <div
                  onClick={() => hundleopenGhlGrantAdminAccess(row._id)}
                  className={classes.item}
                >
                  <StyledMenuIcon 
                      icon={LockIcon}
                  />
                  <span>
                    GHL admin access
                  </span>
                </div>
              </BootstrapTooltip>
            )}
            {canDo(permissions.DELETE_AGENCY) && (
              <BootstrapTooltip title="Delete">
                <div
                  onClick={() => hundleOpenDeleteAgencyModal(row.contact_id, row._id)}
                  className={classes.item}
                >
                  <StyledMenuIcon 
                      icon={TrashIcon}
                  />
                  <span>
                    Delete
                  </span>
                </div>
              </BootstrapTooltip>
            )}
            {canDo(permissions.UPDATE_AGENCY) && (
              <BootstrapTooltip title="Sync with GHL">
                <div
                  onClick={() => syncAgency([row._id], setsnackbarstatuss, setisLoading)}
                  className={classes.item}
                >
                  <StyledMenuIcon 
                      icon={ArrowsRotateIcon}
                  />
                  <span>
                    Sync with GHL
                  </span>
                </div>
              </BootstrapTooltip>
            )}
            {canDo(permissions.CREATE_SUB_LOCATION) && (
              <BootstrapTooltip title="Create sub location">
                <div
                  onClick={() => {
                    createSubLocation([row._id], setsnackbarstatuss, setisLoading)
                  }}
                  className={classes.item}
                >
                  <StyledMenuIcon 
                      icon={SquarPlusIcon}
                  />
                  <span>
                    Create sub location
                  </span>
                </div>
              </BootstrapTooltip>
            )}
            {(canDo(permissions.UPDATE_SNAPSHOT) && canDo(permissions.READ_SNAPSHOT)) && (
              <BootstrapTooltip title="Import snapshot">
                <div
                  onClick={() =>{
                    hundleopenImportSnapShotModal(
                        [row._id],
                        setsnackbarstatuss,
                        row.locations
                      )
                      handleClose()
                    }}
                  className={classes.item}
                >
                  <StyledMenuIcon 
                    icon={ArrowsRotateIcon}
                  />
                  <span>
                    Import snapshot
                  </span>
                </div>
              </BootstrapTooltip>
            )}
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {canDo(permissions.CREATE_SUB_USER) && (
              <BootstrapTooltip title="Create sub user">
                <div
                  onClick={() => createSubUser([row._id], setsnackbarstatuss, setisLoading)}
                  className={classes.item}
                >
                  <StyledMenuIcon 
                      icon={AddUserIcon}
                  />
                  <span>
                    Create sub user
                  </span>
                </div>
              </BootstrapTooltip>
            )}
            {canDo(permissions.ADD_LOCATION) && (
              <BootstrapTooltip title="Add location">
                <div
                  onClick={() => hundleOpenAddLocationModal(row._id)}
                  className={classes.item}
                >
                  <StyledMenuIcon 
                      icon={OneIcon}
                  />
                </div>
                <span>
                  Add location
                </span>
              </BootstrapTooltip>
            )}
            {canDo(permissions.UPDATE_MAIN_LOCATION_AGENCY) && (
              <BootstrapTooltip title="Update  main location">
                <div
                  onClick={() => hundleOpenMainLocationUpdateModal(row._id, row.mainLocationId)}
                  className={classes.item}
                >
                  <StyledMenuIcon 
                      icon={OneIcon}
                  />
                  <span>
                    Update  main location
                  </span>
                </div>
              </BootstrapTooltip>
            )}
            {canDo(permissions.SYNC_USER_ACCESS) && (
              <BootstrapTooltip title="Sync  users access to locations">
                <div
                  onClick={() => syncAgencyUserAccess([row._id], setsnackbarstatuss, setisLoading)}
                  className={classes.item}
                >
                  <StyledMenuIcon 
                      icon={ArrowsRotateIcon}
                  />
                  <span>
                   Sync  users access to locations
                  </span>
                </div>
              </BootstrapTooltip>
            )}
            {canDo(permissions.UPGRADE_USER) && (
              <BootstrapTooltip title="Upgrade user">
                <div
                  onClick={() => {
                    hundleOpenUpgradeUserModal(
                      [row._id],
                      setsnackbarstatuss,
                      setisLoading
                    )
                  }}
                  className={classes.item}
                >
                  <StyledMenuIcon 
                      icon={TrendIcon}
                  />
                  <span>
                    Upgrade user
                  </span>
                </div>
              </BootstrapTooltip>
            )}
            {canDo(permissions.UPDATE_SNAPSHOT) && (
              <BootstrapTooltip title="Update snapshot">
                <div
                  onClick={() => {
                    hundleopenUpdateSnapShotModal(
                      [row._id],
                      setsnackbarstatuss,
                      row.locations
                    )
                    handleClose()
                  }}
                  className={classes.item}
                >
                  <StyledMenuIcon 
                      icon={ArrowsRotateIcon2}
                  />
                  <span>
                    Update snapshot
                  </span>
                </div>
              </BootstrapTooltip>
            )}
            {canDo(permissions.UPDATE_AGENCY) && (
              <BootstrapTooltip title="Update Api Key">
                <div
                  onClick={() => {
                    hundleOpenApiKeyUpdateModal(row._id, row.agencyApiKey)
                  }}
                  className={classes.item}
                >
                  <StyledMenuIcon 
                      icon={KeysIcon}
                  />
                  <span>
                    Update Api Key
                  </span>
                </div>
              </BootstrapTooltip>
            )}
          </div>
        </div>
      </Menu>

      <UpgradeUserModal
        hundleOpenUpgradeUserModal={hundleOpenUpgradeUserModal}
        openUpgradeUserModal={openUpgradeUserModal}
        agencyId={agencyId}
        fetchData={fetchData}
        mainLocation={mainLocation}
      ></UpgradeUserModal>

     <UpdateApiKeyModal
        hundleOpenApiKeyUpdateModal={hundleOpenApiKeyUpdateModal}
        openApiKeyUpdateModal={openApiKeyUpdateModal}
        agencyId={agencyId}
        fetchData={fetchData}
        apiKey={apiKey}
      ></UpdateApiKeyModal>

      <UpdateSnapShotModal
        hundleopenUpdateSnapShotModal={hundleopenUpdateSnapShotModal}
        openUpdateSnapShotModal={openUpdateSnapShotModal}
        agencyId={agencyId}
        fetchData={fetchData}
        mainLocation={mainLocation}
        locations={locations}
      ></UpdateSnapShotModal>

      <ImportSnapshotModal
        hundleopenImportSnapShotModal={hundleopenImportSnapShotModal}
        openImportSnapShotModal={openImportSnapShotModal}
        agencyId={agencyId}
        fetchData={fetchData}
        mainLocation={mainLocation}
        locations={locations}
      />

      <UpdateMainLocationModal
        hundleOpenMainLocationUpdateModal={hundleOpenMainLocationUpdateModal}
        openMainLocationUpdateModal={openMainLocationUpdateModal}
        agencyId={agencyId}
        fetchData={fetchData}
        mainLocation={mainLocation}
      ></UpdateMainLocationModal>

      <GrantGhlAdminAccessModal
        hundleopenGhlGrantAdminAccess={hundleopenGhlGrantAdminAccess}
        openGhlGrantAdminAccess={openGhlGrantAdminAccess}
        agencyId={agencyId}
        fetchData={fetchData}
      ></GrantGhlAdminAccessModal>

      <ChangeDomainModal
        openChangeDomainModal={openChangeDomainModal}
        hundleOpenChangeDomainModel={hundleOpenChangeDomainModel}
        contactId={contactId}
        fetchData={fetchData}
      ></ChangeDomainModal>

      <AddAgencyModal
        openAddLocationModal={openAddLocationModal}
        hundleOpenAddLocationModal={hundleOpenAddLocationModal}
        agencyId={agencyId}
        fetchData={fetchData}
      ></AddAgencyModal>

      <DeleteAgencyModal
        openDeleteAgencyModal={openDeleteAgencyModal}
        hundleOpenDeleteAgencyModal={hundleOpenDeleteAgencyModal}
        agencyId={agencyId}
        fetchData={fetchData}
      ></DeleteAgencyModal>

    </div>
  )
}
