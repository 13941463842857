import React, { useEffect, useState, useContext } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import * as AdminRouter from '../../routing/admin.json'
import Snackbar from '../snackbar';
import {AuthContext} from '../../contexts/auth.context'
import {http} from '../../helpers/http'
import { StyledDialogActions, StyledDialogContent, StyledDialogContentText, StyledDialogTitle, StyledModal } from './StyledModal';
import Button from '../Button';
import StyledInput from '../StyledInput';
import StyledSelect from '../StyledSelect';
import styled, { useTheme } from 'styled-components';

const StyledLabel = styled(InputLabel)`
  color: ${({theme}) => theme.text};
  opacity: 0.7;
`

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });
let roles = []
let tags = []
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function UpdateAdminModal({openUpdateComand, hundleOpenUpdateModel, fetchData, adminToUpdate, setadminToUpdate}) {

  const styledTheme = useTheme()
  let initSnackBar = {
    open : false, message : null, state : 'error'
}

const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        background: styledTheme.body2,
        color: styledTheme.text,
      },
    },
  };

const [snackbarstatus, setsnackbarstatuss] = useState(initSnackBar)

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(openUpdateComand)
  }, [openUpdateComand])


  const onSubmit =async(data) => {

    setsnackbarstatuss(initSnackBar)
    const url = `${process.env.REACT_APP_API_BASE_URL}${AdminRouter.UPDATE_ADMIN}`
    const method = 'POST'
    const body = {first_name, last_name, permissions : permissionsList, _id : adminToUpdate._id, tags : tagsList}
    const headers = {
        Authorization : `Bearer ${localStorage.getItem('token')}`
    }
    let res = await http({url , method, headers, body})
    setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})

    if(res?.response?.success){
      handleClose()
      fetchData()
    }

    console.log(res)
  };

  useEffect(() => {
    setpermissionsList(adminToUpdate?.permissions?? [])
    settagsList(adminToUpdate?.tags?? [])
  }, [openUpdateComand])

  let authContext = useContext(AuthContext)
  let {profile} = authContext
  console.log('profile')
  console.log(profile)
  if(profile){
    roles = profile.permissions
    tags = profile.tags
  }

  
//  useEffect(() => {
 //   setOpen(adminToUpdate)
//}, [adminToUpdate])

  const [selectedPermissions, setselectedPermissions] = useState([])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setpermissionsList(event.target.value);
  };

  const handleTagsChange = (event) => {
    settagsList(event.target.value);
  };

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setpermissionsList(value);
  };

  const handleClose = () => {
    setadminToUpdate(null)
    hundleOpenUpdateModel()
    setpermissionsList([])
    settagsList([])
    setOpen(false);
  };
console.log('adminToUpdate')
console.log(adminToUpdate)
const [permissionsList, setpermissionsList] = React.useState(adminToUpdate?.permissions?? []);
const [tagsList, settagsList] = useState(adminToUpdate?.tags?? [])
const [first_name, setfirst_name] = useState(adminToUpdate?.first_name?? '')
const [last_name, setlast_name] = useState(adminToUpdate?.last_name ?? '')

useEffect(() => {
  console.log('special adminToUpdate')
  console.log(adminToUpdate)
  setfirst_name(adminToUpdate?.first_name)
  setlast_name(adminToUpdate?.last_name)
  setpermissionsList(adminToUpdate?.permissions?? [])
  settagsList(adminToUpdate?.tags?? [])
}, [adminToUpdate])

return (
    <div>
      <StyledModal open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <StyledDialogTitle>Update Admin</StyledDialogTitle>
        <StyledDialogContent>
          <form onSubmit={()=>onSubmit()}>
            <StyledDialogContentText>
              To update the admin, please enter your following informations.
            </StyledDialogContentText>
            <StyledInput
              autoFocus
              margin="dense"
              id="first_name"
              onChange={(e)=>setfirst_name(e.target.value)}
              label="First Name"
              defaultValue={adminToUpdate?.first_name}
              type="text"
              fullWidth
              variant='outlined'
              inputProps={{
                style: {
                  height: 30,
                  borderRadius: 15
                }
              }}
            />
            <StyledInput
              autoFocus
              margin="dense"
              id="last_name"
              defaultValue={adminToUpdate?.last_name}
              onChange={(e)=>setlast_name(e.target.value)}
              label="Last Name"
              type="text"
              fullWidth
              variant='outlined'
              inputProps={{
                style: {
                  height: 30,
                  borderRadius: 15
                }
              }}
            />
            <FormControl 
              variant="outlined" 
              fullWidth
              style={{ marginTop: "10px", }}
            >
              <StyledLabel id="demo-mutiple-chip-label" fullWidth>Permissions</StyledLabel>
              <StyledSelect
                fullWidth
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                defaultValue={permissionsList}
                onChange={handleChange}
                // input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {roles.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>

            <FormControl 
              variant="outlined" fullWidth
              style={{ marginTop: "10px", }}
            >
              <StyledLabel id="demo-mutiple-chip-label" fullWidth>Tags</StyledLabel>
              <StyledSelect
                fullWidth
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                variant='outlined'
                defaultValue={tagsList}
                onChange={handleTagsChange}
                // input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {tags.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          </form>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button 
            onClick={handleClose} 
            background={'#FD658520'} 
            color="#FD6585"
            width={'120px'}
            height={'50px'}
            fontSize={'16px'}
          >
            Cancel
          </Button>
          <Button 
            onClick={()=>onSubmit()}
            background="#26C49A1A" 
            width={'120px'}
            height={'50px'}
            color={'#26C49A'}
            fontSize={'16px'}
          >
            Save
          </Button>
        </StyledDialogActions>
      </StyledModal>
      <Snackbar key="zmar" open={snackbarstatus.open}  message={snackbarstatus.message} state={snackbarstatus.state}></Snackbar>
    </div>
  );
}
