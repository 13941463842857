import styled from 'styled-components'
import {TablePagination} from '@mui/material';

const Pagination = styled(TablePagination)`
    background: #117D96;
    border-radius: 0px 0px 40px 40px;
    height: 60px;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-family: Rubik;
    z-index: 1;
    position: relative;
    p {
        font-family: Rubik;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        color: #FFFFFF;
    }
    .MuiIconButton-root.Mui-disabled {
        color: #FFFFFF20
    }
    .MuiTablePagination-actions, .MuiSelect-icon, input {
        color: #FFFFFF
    }
    .MuiInputBase-root {
        font-family: Rubik !important;
        font-style: normal !important;
        color: #FFFFFF !important;
    }
`

function StyledPagination(props) {
    return(
        <Pagination 
            rowsPerPageOptions={props.rowsPerPageOptions}
            component="div"
            count={props.count}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            onPageChange={(event, page) => props.onChangePage(event, page)}
            onRowsPerPageChange={(event) => props.onChangeRowsPerPage(event)}
        />
    )
}

export default StyledPagination