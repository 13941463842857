import styled from 'styled-components'

const Container = styled.div`
    width: ${props => props.width ? props.width : '100%'};
    height: ${props => props.height ? props.height : (props.isOpen ? '60px' : '50px')};
    background: ${props => props.background ? props.background : (props.isActive ? '#26C49A' : '#C4C4C41A')};
    border: none;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: ${props => props.isOpen ? '' : 'center'};
    margin-bottom: 10px;
    padding: ${props => props.isOpen ? '10px 20px' : '0px'};
    cursor: pointer;
    min-height: 50px;
    min-width: 50px;
    transition: 0.3s;
    &:hover {
        background: ${props => (props.isActive ? '#26C49A' : '')};
    }
    img {
        width: 25px;
    }
    h3 {
        font-weight: ${props => props.isActive ? '500' : '400'};
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.38px;
        color: ${props => props.isActive ? '#FFFFFF' : '#a5a5a5'};
        margin-left: 20px;
        display: ${props => props.isOpen ? '' : 'none'};
    }
`

function DrawerItem({icon, title, width, height, isActive, isOpen, background, onClick}) {
    return(
        <Container 
            width={width} 
            height={height} 
            isActive={isActive} 
            isOpen={isOpen}
            background={background}
            onClick={() => {onClick()}}
        >
            {icon}
            <h3>
                {title}
            </h3>
        </Container>
    )
}

export default DrawerItem
