import { createContext, useState, useEffect } from "react";
import {http} from '../helpers/http'
import * as AuthRouter from '../routing/auth.json'
export const AuthContext = createContext()

const AuthContextProvider = (props) => {

    const fetchProfile = async() =>{
        const url = `${process.env.REACT_APP_API_BASE_URL}${AuthRouter.PROFILE}`
        const method = 'POST'
        const headers = {
            Authorization : `Bearer ${localStorage.getItem('token')}`
        }
        let res = await http({url , method, headers})

        if(res?.statusCode === 401){
            setToken(null)
            setIsAuth(false)
        }

        if(res?.response?.success){
            setPermissions(res.response.data?.permissions)
            setprofile(res.response.data)
        }
        console.log('fetchProfile')
        console.log(res)

    }

    let [ isAuth, setIsAuth ] = useState(localStorage.getItem('token') ? true : false)
    let [ token, setToken ] = useState(null)
    let [ permissions, setPermissions ] = useState([])
    const [profile, setprofile] = useState(null)

    useEffect(() => {
        fetchProfile()
    }, [])

    const canDo  = (action) => {


        return permissions.includes(action)
    }

    const auth = (tokenData) => {
        fetchProfile()
        setToken(tokenData)
        setIsAuth(!isAuth)
        localStorage.setItem('token', tokenData)
    }

    const logout = () => {
        setprofile(null)
        setPermissions([])
        setToken(null)
        setIsAuth(!isAuth)
        localStorage.setItem('token', null)
    }

    return ( 
    <AuthContext.Provider value={{isAuth,auth, token, logout, profile, canDo}}>
                {props.children}
    </AuthContext.Provider>
    );
}

export default AuthContextProvider;