import * as AgencyRouter from '../routing/agency.json'
import {http} from '../helpers/http'

export const syncAgency = async(agency_id, setsnackbarstatuss, setisLoading) => {
    console.log(setsnackbarstatuss)
    setsnackbarstatuss({    open:false , message : '', state :  'success'})
    setisLoading(true)
    const url = `${process.env.REACT_APP_API_BASE_URL}${AgencyRouter.SYNC_AGENCY}`
    const method = 'POST'
    const headers = {
        Authorization : `Bearer ${localStorage.getItem('token')}`
    }
    const body = {agency_id}
    let res = await http({url , method, headers, body})
    console.log(res)
    setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})
    setisLoading(false)
}

export const syncAgencySubscription = async(agency_id, setsnackbarstatuss, setisLoading) => {
    console.log(setsnackbarstatuss)
    setsnackbarstatuss({    open:false , message : '', state :  'success'})
    setisLoading(true)
    const url = `${process.env.REACT_APP_API_BASE_URL}${AgencyRouter.SYNC_ONE_AGENCY_SUB}`
    const method = 'POST'
    const headers = {
        Authorization : `Bearer ${localStorage.getItem('token')}`
    }
    const body = {agency_id}
    let res = await http({url , method, headers, body})
    console.log(res)
    setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})
    setisLoading(false)
}

export const syncAllAgencySubscription = async(setsnackbarstatuss, setisLoading) => {
    console.log(setsnackbarstatuss)
    setsnackbarstatuss({    open:false , message : '', state :  'success'})
    setisLoading(true)
    const url = `${process.env.REACT_APP_API_BASE_URL}${AgencyRouter.SYNC_ALL_AGENCIES_SUB}`
    const method = 'POST'
    const headers = {
        Authorization : `Bearer ${localStorage.getItem('token')}`
    }
    const body = {}
    let res = await http({url , method, headers, body})
    console.log(res)
    setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})
    setisLoading(false)
}

export const createSubUser = async(agency_id, setsnackbarstatuss, setisLoading) => {
setsnackbarstatuss({    open:false , message : '', state :  'success'})
setisLoading(true)
const url = `${process.env.REACT_APP_API_BASE_URL}${AgencyRouter.CREATE_SUB_USER}`
const method = 'POST'
const headers = {
    Authorization : `Bearer ${localStorage.getItem('token')}`
}
const body = {agency_id}
let res = await http({url , method, headers, body})
console.log(res)
setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})
setisLoading(false)
}

export const createSubLocation = async(agency_id, setsnackbarstatuss, setisLoading) => {
    setsnackbarstatuss({    open:false , message : '', state :  'success'})
    setisLoading(true)
    const url = `${process.env.REACT_APP_API_BASE_URL}${AgencyRouter.CREATE_SUB_LOCATION}`
    const method = 'POST'
    const headers = {
        Authorization : `Bearer ${localStorage.getItem('token')}`
    }
    const body = {agency_id}
    let res = await http({url , method, headers, body})
    console.log(res)
    setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})
    setisLoading(false)
}

export const syncAgencyUserAccess = async(agency_id, setsnackbarstatuss, setisLoading) => {
    setsnackbarstatuss({    open:false , message : '', state :  'success'})
    setisLoading(true)
    const url = `${process.env.REACT_APP_API_BASE_URL}${AgencyRouter.SYNC_USER_ACESS}`
    const method = 'POST'
    const headers = {
        Authorization : `Bearer ${localStorage.getItem('token')}`
    }
    const body = {agency_id}
    let res = await http({url , method, headers, body})
    console.log(res)
    setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})
    setisLoading(false)
}    


export const createMailreach = async(agency_id, setsnackbarstatuss, setisLoading) => {
    setsnackbarstatuss({    open:false , message : '', state :  'success'})
    setisLoading(true)
    const url = `${process.env.REACT_APP_API_BASE_URL}${AgencyRouter.CREATE_MAIL_REACH}`
    const method = 'POST'
    const headers = {
        Authorization : `Bearer ${localStorage.getItem('token')}`
    }
    const body = {agency_id}
    let res = await http({url , method, headers, body})
    console.log(res)
    setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})
    setisLoading(false)
    }