import {useState, useEffect} from 'react'
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled, {useTheme} from 'styled-components'

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { StyledCheckbox } from './StyledCheckbox';
import StyledSelect from './StyledSelect';
import StyledInput from './StyledInput';
import { Autocomplete } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StyledAccordionSummary = styled(AccordionSummary)`
  background: #117D96 !important;
  padding: 10px 20px !important;
  display: flex !important;
  align-items: center !important;
  h5 {
    margin: 0 !important;
    font-family: Rubik !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    color: #FFFFFF !important
  }
  .Mui-expanded {
    margin: 0 !important;
    // background: ${({theme}) => theme.body} !important
  }
  .MuiAccordionSummary-expandIconWrapper {
    color: #FFFFFF 
  }
`

const StyledAccordion = styled(Accordion)`
  width: 100%,
`

export default function CustomCard({title, data, width}) {
  const styledTheme = useTheme()
  const [selectedKeys, setSelectedKeys] = useState([]);

  useEffect(() => {
    if(data){
      data.map((value, index) => {
        if(index<9){
          setSelectedKeys(selectedKeys => [...selectedKeys ,value.key])
        }
        return null;
      })
    }
  }, [data])

  const handleChange = (item, index) => {
    console.log({selectedKeys})
    if(selectedKeys.includes(item?.key)) {

      let targetIndex = selectedKeys.findIndex((each) => each.key === item.key)
      console.log({index})
      const temp = [...selectedKeys];
      temp.splice(targetIndex, 1);
      setSelectedKeys(temp);
    }else{
      setSelectedKeys(prev => [...prev, item.key])
    }
  };

  const useStyles = makeStyles({
  root: {
    width: '100% !important',
    textAlign : 'left !important',
    height: 'fit-content !important',
    marginBottom: 20,
    background: `${styledTheme.body} !important`,
    boxShadow: `0px 0px 44px ${styledTheme.shadow} !important`,
    borderRadius: '20px 20px 40px 40px !important',
    '& .MuiAutocomplete-paper': {
      background: `${styledTheme.body} !important`,
    }
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: 10,
    maxWidth: '92vw !important',
  },
  boldText: {
      fontWeight : 'bold'
  },
  pp : {
    overflowWrap: 'break-word',
    padding: '5px !important',
    marginRight: '7px !important',
    marginBottom: '7px !important',
    borderRadius: 5,
    background: '#26C49A1A',
    fontFamily: 'Rubik !important',
    fontStyle: 'normal',
    fontWeight: '400 !important',
    fontSize: '15px !important',
    color: `${styledTheme.text} !important`,
    textAlign: 'left',
  },
  filters: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  },
  filterItem: {
    fontFamily: 'Rubik !important',
    fontStyle: 'normal',
    fontWeight: '500 !important',
    fontSize: '15px !important',
    color: `${styledTheme.text} !important`,
    marginLeft: 10
  },
  dropdowns: {
    
  }
});

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <StyledAccordion>
        <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            // id="panel1a-header"
        >
          <h5 className={classes.title}>
            {title}
          </h5>
        </StyledAccordionSummary>
        <AccordionDetails style={{ background: styledTheme.body2 }}>
          <CardContent>
            <div className={classes.filters}>
              <FormControl sx={{ m: 1, width: 300 }}>
                {/* <InputLabel id="demo-multiple-checkbox-label">Select more fields to display</InputLabel> */}
                <Autocomplete
                  disablePortal
                  className={classes.dropdowns}
                  id="combo-box-demo"
                  options={data}
                  sx={{ width: 300 }}
                  getOptionLabel={(option) => option.key}
                  renderOption={(item, index) => {
                    return(
                      <MenuItem 
                        key={item?.key} 
                        value={item?.key} 
                        onClick={() => handleChange(item, index)}>
                        <StyledCheckbox checked={selectedKeys.includes(item?.key)} />
                        <span className={classes.filterItem}>
                          {item?.key}
                        </span>
                      </MenuItem>
                    )
                  }}
                  renderInput={(params) => <StyledInput {...params} label="Select more fields to display" />}
                />
                {/* <StyledSelect
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedKeys}
                  onChange={handleChange}
                  input={<OutlinedInput label="Select more fields to display" />}
                  renderValue={(selected) => {
                    return(
                      <span style={{ marginRight: 10 }}>
                        {selected.join(', ')}
                      </span>
                    )
                  }}
                  MenuProps={MenuProps}
                >
                  {data && data.length > 0 && data.map((item) => (
                    <MenuItem key={item?.key} value={item?.key}>
                      <StyledCheckbox checked={selectedKeys.includes(item?.key)} />
                      <span className={classes.filterItem}>
                        {item?.key}
                      </span>
                    </MenuItem>
                  ))}
                </StyledSelect> */}
              </FormControl>
            </div>

            <div className={classes.content}>
              {
                data && data.length > 0 && data.map((item,i)=> {
                  if(selectedKeys.includes(item?.key)){
                    return (
                      <Typography 
                        variant="body2" 
                        color="textSecondary" 
                        component="p" 
                        className={classes.pp}
                        style={{
                          width: width
                        }}
                      >
                        <span className={classes.boldText}> {item?.key} : </span>{
                          typeof item.value == 'object'  ? 
                            JSON.stringify(item.value)
                          :
                            item?.value?.toString()
                        }
                      </Typography>
                    )
                  }else{
                    return null
                  }
  
                })
              }

            </div>
          </CardContent>
        </AccordionDetails>
      </StyledAccordion>
    </Card>
  );
}
