import styled from 'styled-components'

const Container = styled.div`
    width: ${props => props.width ? props.width : '100%'};
    min-height: 50px; min-width: 50px;
    background: transparent;
    display: flex;
    align-items: center;
    margin: ${props => props.margin ? props.margin : '0'};
    h2 {
        font-weight: 500;
        font-size: 28px;        
        color: ${({ theme }) => theme.text};
    }
`

function Title({title, width, height, margin}) {
    return(
        <Container 
            width={width} 
            height={height} 
            margin={margin}>
            <h2>
                {title}
            </h2>
        </Container>
    )
}

export default Title
