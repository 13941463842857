import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
// import { DialogContentText, DialogTitle, DialogContent, DialogActions, Dialog } from '@mui/material';

const StyledModal = styled(Dialog)`
    .MuiDialog-paper {
        min-width: 350px;
        max-width: fit-content !important;
        border-radius: 20px;
        max-width: 80% !important;
        background: ${({theme}) => theme.body}
    }
`

const StyledDialogTitle = styled(DialogTitle)`
    text-align: left;
    border-bottom: 1px solid #117D9620;
    font-family: Rubik !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    color:${({theme}) => theme.text} !important;
    h2 {
        font-family: Rubik !important;
        font-weight: 600 !important;
        font-size: 20px !important;
        color: ${({theme}) => theme.text} !important;
    }
`

const StyledDialogContent = styled(DialogContent)`
    padding: 20px;
    text-align: left;
    padding-top: 20px !important;
    form {
        color: #26C49A
    }
`

const StyledDialogActions = styled(DialogActions)`
    border-top: 1px solid #117D9620;
    color: #26C49A
`

const StyledDialogContentText = styled(DialogContentText)`
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: ${({theme}) => theme.text};
    opacity: 0.7
`

export {
    StyledModal, 
    StyledDialogTitle, 
    StyledDialogContent, 
    StyledDialogActions, 
    StyledDialogContentText
}