import React, { useEffect, useState } from 'react';
import * as AdminRouter from '../../routing/agency.json'
import Snackbar from '../snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import {http} from '../../helpers/http'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { StyledModal, StyledDialogTitle, StyledDialogContent, StyledDialogActions } from './StyledModal';
import Button from '../Button';
import StyledSelect from '../StyledSelect';
import { FormControl } from '@material-ui/core';

export default function UpgradeUserModal({openUpgradeUserModal, hundleOpenUpgradeUserModal, fetchData, agencyId}) {

  let initSnackBar = {
    open : false, message : null, state : 'error'
}

const [snackbarstatus, setsnackbarstatuss] = useState(initSnackBar)

  const [open, setOpen] = React.useState(false);
    const [isLoading, setisLoading] = useState(false)
    const [upgradeTo, setupgradeTo] = useState("dfy")

  useEffect(() => {
    setOpen(openUpgradeUserModal)
  }, [openUpgradeUserModal])

  const onSubmit =async(data) => {
    setisLoading(true)
    console.log('this is data')
    console.log(data)

    setsnackbarstatuss(initSnackBar)
    const url = `${process.env.REACT_APP_API_BASE_URL}${AdminRouter.UPGRADE_USER}`
    const method = 'POST'
    const body = {
      agency_id: agencyId,
      upgradeTo
    }
    const headers = {
        Authorization : `Bearer ${localStorage.getItem('token')}`,
    }
    let res = await http({url , method, headers, body})
    setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})

    if(res?.response?.success){
      handleClose()
      fetchData()
    }

    setisLoading(false)
    console.log(res)
  };

  useEffect(() => {
      setOpen(openUpgradeUserModal)
  }, [openUpgradeUserModal])

  const [selectedPermissions, setselectedPermissions] = useState([])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPersonName(value);
  };

  const handleClose = () => {
    hundleOpenUpgradeUserModal()
    setOpen(false);
    setsnackbarstatuss(initSnackBar)
  };

  const [personName, setPersonName] = React.useState([]);

  return (
    <div>
      <StyledModal open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <StyledDialogTitle>Upgrade user</StyledDialogTitle>
        <StyledDialogContent>
          <form onSubmit={()=>onSubmit()}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-label" style={{ marginRight: 10 }}>To</InputLabel>
                <StyledSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={upgradeTo}
                  onChange={(e)=>setupgradeTo(e.target.value)}
                >
                  <MenuItem value={"dfy"}>dfy</MenuItem>
                  <MenuItem value={"dwy"}>dwy</MenuItem>
                </StyledSelect>
              </FormControl>
            </div>
          </form>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button 
            onClick={handleClose} 
            background={'#FD658520'} 
            color="#FD6585"
            width={'120px'}
            height={'50px'}
            fontSize={'16px'}
          >
            Cancel
          </Button>
            {
              isLoading ?
                <CircularProgress  />
              :
                <Button 
                  onClick={()=>onSubmit()} 
                  background="#26C49A1A" 
                  width={'120px'}
                  height={'50px'}
                  color={'#26C49A'}
                  fontSize={'16px'}
                >
                  Save
                </Button>
            }

        </StyledDialogActions>
      </StyledModal>
      <Snackbar key="zmar" open={snackbarstatus.open}  message={snackbarstatus.message} state={snackbarstatus.state}></Snackbar>
    </div>
  );
}
