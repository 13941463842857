// import TextField from '@material-ui/core/TextField';
import { TextField } from '@mui/material';
import styled from 'styled-components'

const StyledInput = styled(TextField)`
background: #C4C4C41A;
height: 50px;
// max-width: 350px;
.MuiOutlinedInput-root span  {
    font-family: Rubik !important;
}
.MuiOutlinedInput-root {
    background: #C4C4C41A;
    height: 50px;
    font-family: Rubik !important;
    color: ${({ theme }) => theme.text};
}
.MuiOutlinedInput-root>input::placeholder {
    color: ${({ theme }) => theme.text};
}
& label.Mui-focused {
  color: ${({ theme }) => theme.text};
}
.MuiOutlinedInput-notchedOutline {
    border-color: #C4C4C41A
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #26C49A
}
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #C4C4C490
}

label {
    color: ${({theme}) => theme.text};
    opacity: 0.7
}
`

export default StyledInput