import styled from 'styled-components'

const Container = styled.div`
    width: 100%; height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({theme}) => theme.body};
`

const Card = styled.div`
    background: ${({theme}) => theme.body2};
    box-shadow: 0px 3px 40px ${({theme}) => theme.shadow};
    border-radius: 30px;
    max-width: 800px;
    width: 600px;
    min-height: 350px;
    padding: 30px 50px;
    form {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .input-container {
        display: flex;
        align-items: center;
        background: rgba(196, 196, 196, 0.1);
        border-radius: 20px;
        width: 100%;
        height: 60px;
        margin-bottom: 30px;
        position: relative
    }
    input {
        background: none;
        width: 80%;
        height: 100%;
        padding: 10px 20px;
        border: none;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: ${({theme}) => theme.text}
    }
    input::placeholder {
        color: #2A343F26
    }
    input:focus{
        outline: none;
    }
`

const Icon = styled.div`
    background: ${props => props.isActive ? '#26C49A' : "#D0D2D4"};
    border-radius: 20px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 25px
    }
`

const ErrorMessage = styled.small`
    color: tomato;
    position: absolute;
    bottom: -15px;
    left: 70px
`

export { Container, Card, Icon, ErrorMessage }