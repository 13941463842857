import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import * as SnapshotsRouter from '../routing/snapshots.json'
import * as UtilsRouter from '../routing/utils.json'
import {http} from '../helpers/http'
import Snackbar from '../components/snackbar'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import CreateAdminModal from './modals/createAdminModal';
import {AuthContext} from '../contexts/auth.context'
import getPermissions from '../permissions.ts'
import StyledInput from './StyledInput';
import { StyledTableBodyCell, StyledTableRow } from './StyledTableBody';
import StyledPagination from './StyledPadination';
import { StyledTableCell, StyledTableHead } from './StyledTableHeader';
import styled from 'styled-components'
import { FormControl, IconButton, InputLabel, MenuItem } from '@mui/material';
import EditIcon from '../assets/images/edit-icon.svg'
import TrashIcon from '../assets/images/trash-icon.svg'
import { StyledDialogActions, StyledDialogContent, StyledDialogContentText, StyledDialogTitle, StyledModal } from './modals/StyledModal';
import StyledSelect from './StyledSelect';
import Button from './Button';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { StyledSwitch } from './StyledSwitch';
import { useTheme } from 'styled-components';
import Dropzone from 'react-dropzone'
import {FiUpload} from 'react-icons/fi'

const Badge = styled.div`
    padding: 5px 10px;
    background: #26C49A20;
    color: #26C49A;
    font-size: 12px;
    font-weight: 600;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 20px;
`

const FileUlpoadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  .dropzone-input {
    padding: 0 10px;
    border: 1px dotted #e0e0e0;
    width: 100%;
    margin-right: 10px;
  }
  section {
    background: #C4C4C41A;
    width: 500px;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .preview-image {
    width: 70px;
    min-width: 70px;
    height: 70px;
    background: #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .preview-image img {
    width: 100%;
    height: 100%;
  }
`

const Loading = styled.div`
  width: 100%; height: 100%;
  background: #00000050;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0; top: 0;
  color: #117D96;
`

const TextArea = styled.textarea`
  width: 100%;
  margin-top: 10px;
  border-radius: 4px;
  border: none;
  background: #C4C4C41A;
  padding: 10px;
  &:focus {
    border: none;
    outline: none
  }
`

const columns = [
  { id: 'id', label: 'id', minWidth: 170 },
  { id: 'name', label: 'name', minWidth: 100 },
  { id: 'tags', label: 'tags', minWidth: 100 },
  { id: 'status', label: 'status', minWidth: 100 },
  {
    id: 'Actions',
    label: 'Actions',
    format: (value) => value.toFixed(2),
  },
];

export default function SnapshotsDatatable(props) {
  const styledTheme = useTheme()
  const permissions = getPermissions()

  let initSnackBar = {
    open : false, message : null, state : ''
  }

  let searchTimer = null;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        background: styledTheme.body2,
        color: styledTheme.text,
      },
    },
  };

  const [search, setsearch] = useState("")
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(5);

  const [snackbarstatus, setsnackbarstatuss] = useState(initSnackBar)
  const [isLoading, setisLoading] = useState(false)
  const [isUpdating, setUpdating] = useState(false)
  const [isDeleting, setDeleting] = useState(false)
  const snapshotsDataInit = []
  const [snapshotsData, setSnapshotsData] = useState(snapshotsDataInit)
  const [openComand, setopenComand] = useState(false)

  const [openEdit, setOpenEdit] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [id, setId] = useState('')
  const [_id, set_Id] = useState('')
  const [name, setName] = useState('')
  const [video, setVideo] = useState('')
  const [image, setImage] = useState('')
  const [uploadingImage, setUploadingImage] = useState(false)
  const [facebookExample, setFacebookExample] = useState('')
  const [uploadingFacebookExample, setUploadingFacebookExample] = useState(false)
  const [setupGuide, setSetupGuide] = useState('')
  const [overviewText, setOverviewText] = useState('')
  const [allTags, setAllTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [isEdit, setIsEdit] = useState(false)

const handleSearchChange = (evt) => {
  const val = evt.target.value;
  clearTimeout(searchTimer);
  searchTimer = setTimeout(() => {
      setsearch(val)
  }, 1000);
}
let authContext = useContext(AuthContext)

const useStyles = makeStyles((theme)=>({
  head: {
    backgroundColor: "#3f51b54d",
    color:"black",
  },
  root: {
    width: '100%',
  },
  container: {
    borderRadius: '20px 20px 0 0',
    background: styledTheme.body2,
    boxShadow: `0px 0px 44px ${styledTheme.shadow}`,
    position: 'relative',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#26C49A',
  },
  addButton : {
  margin : '20px'
  },
  header : {
    backgroundColor : 'white',
    flex: 1,
    padding : '5px',
    marginBottom : '30px',
    borderRadius : '5px'
  },
  headerText : {
    fontWeight : 'bold',
    fontSize : '20px'
  },
  filters : {
    display: "flex",
    height: "fit-content",
    justifyContent: 'flex-end',
    width: '100%',
    position: 'relative',
    marginBottom: 30
  }
}));

useEffect(() => {
 fetchData()
}, [search, page, rowsPerPage])

const {
  canDo
} = authContext

  const fetchData = async() =>{
    setisLoading(true)
    setsnackbarstatuss(initSnackBar)
      const url = `${process.env.REACT_APP_API_BASE_URL}${SnapshotsRouter.SNAPSHOTS_LIST}`
      const method = 'POST'
      let body = {
        filters : {
            search : search,
            pageSize: rowsPerPage,
            page: page,
        }
      }
      const headers = {
          Authorization : `Bearer ${localStorage.getItem('token')}`
      }
      let res = await http({url , method, headers, body})
      if(res?.response?.success){
          setSnapshotsData(res.response.data.records)
          setAllTags(res.response.data.snapShotsTagsList)
          setTotal(res.response.data.total)
      }
      setisLoading(false)
      var message = res.response?.message || (res.response?.success ? "Fetched data successfully" : "Something went wrong")
      var state = res.response?.success ? 'success' : 'error'
      setsnackbarstatuss({open:true , message : message, state : state})
  }

  const hundleOpenCreateModel = () => {
      setopenComand(!openComand)
  }  

    useEffect(() => {
    fetchData()
    }, [])

  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleTagsChange = (event) => {
    setSelectedTags(event.target.value)
  }

  const switchSnapshotStatus = async(snapshot) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}${SnapshotsRouter.SNAPSHOTS_UPDATE}`
    const method = 'POST'
    let body = {
      _id: snapshot._id,
      isActive: !snapshot.isActive,
      id: snapshot.id,
      name: snapshot.name,
      tags: snapshot.tags
    }
    const headers = {
        Authorization : `Bearer ${localStorage.getItem('token')}`
    }
    let res = await http({url , method, headers, body})
    console.log('======== activate',{res})
    if(res?.response?.success){
      fetchData()
    }
  }

  const onSubmit = async() => {
    setUpdating(true)
    setsnackbarstatuss(initSnackBar)
    const url = `${process.env.REACT_APP_API_BASE_URL}${SnapshotsRouter.SNAPSHOTS_UPDATE}`
    const method = 'POST'
    let body = {
      _id: _id,
      id: id,
      name: name,
      tags: selectedTags,
      video: video,
      image: image,
      facebook_example: facebookExample,
      setup_guilde: setupGuide,
      overview_text: overviewText
    }
    const headers = {
        Authorization : `Bearer ${localStorage.getItem('token')}`
    }
    let res = await http({url , method, headers, body})
    console.log('======== update',{res})
    if(res?.response?.success){
      var message = res.response?.message || (res.response?.success ? "Fetched data successfully" : "Something went wrong")
      var state = res.response?.success ? 'success' : 'error'
      setsnackbarstatuss({open:true , message : message, state : state})
      setOpenEdit(false)
      fetchData()
    }
    setUpdating(false)
  }

  const onSubmitCreate = async() => {
    setUpdating(true)
    setsnackbarstatuss(initSnackBar)
    const url = `${process.env.REACT_APP_API_BASE_URL}${SnapshotsRouter.SNAPSHOTS_CREATE}`
    const method = 'POST'
    let body = {
      id: id,
      name: name,
      tags: selectedTags,
      video: video,
      image: image,
      facebook_example: facebookExample,
      setup_guilde: setupGuide,
      overview_text: overviewText
    }
    const headers = {
        Authorization : `Bearer ${localStorage.getItem('token')}`
    }
    let res = await http({url , method, headers, body})
    console.log('======== create',{res})
    if(res?.response?.success){
      var message = res.response?.message || (res.response?.success ? "Fetched data successfully" : "Something went wrong")
      var state = res.response?.success ? 'success' : 'error'
      setsnackbarstatuss({open:true , message : message, state : state})
      setOpenEdit(false)
      fetchData()
    }
    setUpdating(false)
  }

  const onSubmitDelete = async() => {
    setDeleting(true)
    setsnackbarstatuss(initSnackBar)
    const url = `${process.env.REACT_APP_API_BASE_URL}${SnapshotsRouter.SNAPSHOTS_DELETE}`
    const method = 'POST'
    let body = {
      _id: _id,
    }
    const headers = {
        Authorization : `Bearer ${localStorage.getItem('token')}`
    }
    let res = await http({url , method, headers, body})
    if(res?.response?.success){
      var message = res.response?.message || (res.response?.success ? "Fetched data successfully" : "Something went wrong")
      var state = res.response?.success ? 'success' : 'error'
      setsnackbarstatuss({open:true , message : message, state : state})
      setOpenDelete(false)
      fetchData()
    }
    setDeleting(false)
  }

  const uploadImage = async(file, param) => {

    if(param === 'image'){
      setUploadingImage(true)
    }else{
      setUploadingFacebookExample(true)
    }

    const url = `${process.env.REACT_APP_API_BASE_URL}${UtilsRouter.UPLOAD_FILE}`
    const method = 'POST'
    const body = new FormData()
    body.append("file", file[0])

    const headers = {
        "Authorization" : `Bearer ${localStorage.getItem('token')}`,
        "Content-Type": "multipart/form-data"
    }
    let res = await http({url , method, headers, body})
    console.log('========> upload',{res})
    if(res?.response?.data?.url){
      if(param === 'image'){
        setImage(res?.response?.data?.url)
        setUploadingImage(false)
      }else{
        setFacebookExample(res?.response?.data?.url)
        setUploadingFacebookExample(false)
      }
    }else{
      if(param === 'image'){
        setUploadingImage(false)
      }else{
        setUploadingFacebookExample(false)
      }
    }
  }  

  const uploadingLoader = () => {
    return(
      <Loading>
        <CircularProgress color="inherit" size={24} />
      </Loading>
    )
  }

  const updateSnapshotModal = () => {
    return(
      <StyledModal 
        disableEnforceFocus 
        open={openEdit} 
        onClose={() => {
          setOpenEdit(false)
          setId('')
          set_Id('')
          setName('')
          setSelectedTags([])
          setIsEdit(false)
          setVideo('')
          setSetupGuide('')
          setOverviewText('')
          setImage('')
          setFacebookExample('')
        }} 
        onAfterClose={() => {
          setOpenEdit(false)
          setId('')
          set_Id('')
          setName('')
          setSelectedTags([])
          setIsEdit(false)
          setVideo('')
          setSetupGuide('')
          setOverviewText('')
          setImage('')
          setFacebookExample('')
        }} 
        aria-labelledby="form-dialog-title"

      >
        <StyledDialogTitle>{isEdit ? 'Update ' : 'Create New '} Snapshot</StyledDialogTitle>
        <StyledDialogContent>
          <div>
            <StyledDialogContentText>
              To {isEdit ? 'update the snapshot' : 'create a new snapshot'}, please {isEdit ? 'edit ' : 'fill in '} the following informations.
            </StyledDialogContentText>
            <StyledInput
              autoFocus
              margin="dense"
              id="id"
              onChange={(e)=>setId(e.target.value)}
              label="Snapshot ID"
              defaultValue={id}
              type="text"
              fullWidth
              variant='outlined'
              inputProps={{
                style: {
                  height: 30,
                  borderRadius: 15
                }
              }}
            />
            <StyledInput
              autoFocus
              margin="dense"
              id="name"
              defaultValue={name}
              onChange={(e)=>setName(e.target.value)}
              label="Snapshot Name"
              type="text"
              fullWidth
              variant='outlined'
              inputProps={{
                style: {
                  height: 30,
                  borderRadius: 15
                }
              }}
            />
            <FormControl
              fullWidth
              style={{ marginTop: "10px", marginBottom: 5}}
            >
              <InputLabel id="demo-mutiple-chip-label" style={{ padding: '0 5px', color: styledTheme.text, top: -5, opacity: 0.7 }}>Tags</InputLabel>
              <StyledSelect
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                variant='outlined'
                value={selectedTags}
                onChange={handleTagsChange}
                renderValue={(selected) => (
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {selected.map((value) => (
                      <Badge key={value}>
                        {value}
                      </Badge>
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {allTags?.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>

            <StyledInput
              autoFocus
              margin="dense"
              id="video"
              defaultValue={video}
              onChange={(e)=>setVideo(e.target.value)}
              label="Video"
              type="text"
              fullWidth
              variant='outlined'
              inputProps={{
                style: {
                  height: 30,
                  borderRadius: 15,
                  marginTop: 10
                }
              }}
            />

          <FileUlpoadContainer>
            <Dropzone className='dropzone-section' name="image" onDrop={acceptedFiles => uploadImage(acceptedFiles, 'image')}>
              {({getRootProps, getInputProps}) => (
                <section style={{ marginRight: 10 }}>
                  <div className='dropzone-input' {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Drop a <strong>snapshot image</strong><br/>or click to select one</p>
                  </div>
                  <div className='preview-image'>
                    {image === '' ? <FiUpload size={24} color='#E0E0E0' /> : <img src={image} alt=''/>}
                  </div>
                  {uploadingImage && uploadingLoader()}
                </section>
              )}
            </Dropzone>
            <Dropzone className='dropzone-section' name='facebook' onDrop={acceptedFiles => uploadImage(acceptedFiles, 'facebook')}>
              {({getRootProps, getInputProps}) => (
                <section>
                  <div className='dropzone-input' {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Drop a <strong>facebook ads example</strong><br/>or click to select one</p>
                  </div>
                  <div className='preview-image'>
                    {facebookExample === '' ? <FiUpload size={24} color='#E0E0E0' /> : <img src={facebookExample} alt=''/>}
                  </div>
                  {uploadingFacebookExample && uploadingLoader()}
                </section>
              )}
            </Dropzone>
          </FileUlpoadContainer>

          <div style={{ position: 'relative', marginTop: 5 }}>
            <span 
              style={{
                position: 'absolute',
                left: 10,
                top: 0,
                color: '#121620',
                opacity: 0.7,
                fontSize: '1rem',
                fontWeight: '400'
              }}
            >
                Setup Guide
            </span>
            <TextArea
              value={setupGuide}
              onChange={(e)=>setSetupGuide(e.target.value)}
              rows={7}
            />
          </div>

          <div style={{ position: 'relative', marginTop: 5 }}>
            <span 
              style={{
                position: 'absolute',
                left: 10,
                top: 0,
                color: '#121620',
                opacity: 0.7,
                fontSize: '1rem',
                fontWeight: '400'
              }}
            >
                Overview 
            </span>
            <TextArea
              value={overviewText}
              onChange={(e)=>setOverviewText(e.target.value)}
              rows={7}
            />
          </div>

          </div>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button
            onClick={() => {

              setOpenEdit(false)
            }} 
            background={'#FD658520'} 
            color="#FD6585"
            width={'120px'}
            height={'50px'}
            fontSize={'16px'}
          >
            Cancel
          </Button>
          <Button 
            onClick={()=> {
              (isUpdating || uploadingFacebookExample || uploadingImage) ? 
                console.log('loading...') 
              : 
                (isEdit ?  onSubmit() : onSubmitCreate())
            }}
            background="#26C49A1A" 
            width={'120px'}
            height={'50px'}
            color={'#26C49A'}
            fontSize={'16px'}
            disabled={isUpdating}
          >
            {isUpdating ? <CircularProgress size={22} color="inherit" style={{ marginTop: 10 }} /> : 'Save'}
          </Button>
        </StyledDialogActions>
      </StyledModal>
    )
  }

  const deleteSnapshotModal = () => {
    return(
      <StyledModal 
        disableEnforceFocus 
        open={openDelete} 
        onClose={() => {
          setName('')
          setOpenDelete(false)
        }}
        onAfterClose={() => {
          setName('')
        }} 
      >
        <StyledDialogTitle>Delete Snapshot</StyledDialogTitle>
        <StyledDialogContent style={{ textAlign: 'center' }}>
          <div style={{ fontSize: 50 }}>
          <WarningAmberRoundedIcon fontSize={'inherit'} color='warning' />
          </div>
          <StyledDialogContentText style={{ maxWidth: 400 }}>
            Are you sure you want to delete snapshot <strong style={{color: styledTheme.text2}}>{name}</strong> ?
          </StyledDialogContentText>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button
            onClick={() => setOpenDelete(false)} 
            background={'#FD658520'} 
            color="#FD6585"
            width={'120px'}
            height={'50px'}
            fontSize={'16px'}
          >
            Cancel
          </Button>
          <Button 
            onClick={()=> !isDeleting && onSubmitDelete()}
            background="#26C49A1A" 
            width={'120px'}
            height={'50px'}
            color={'#26C49A'}
            fontSize={'16px'}
            disabled={isDeleting}
          >
            {isDeleting ? <CircularProgress size={22} color="inherit" style={{ marginTop: 10 }} /> : 'Delete'}
          </Button>
        </StyledDialogActions>
      </StyledModal>
    )
  }

  return (
    <>
      <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
        <CreateAdminModal fetchData={fetchData} openComand={openComand} hundleOpenCreateModel={hundleOpenCreateModel}></CreateAdminModal>
          
        <div className={classes.filters}>
          <StyledInput
            autoFocus
            style={{ width: 300}}
            id="Search"
            label="Search"
            type="text"
            margin="dense"
            variant='outlined'
            inputProps={{
              style: {
                height: 30,
                borderRadius: 15,
              }
            }}
            onChange={handleSearchChange}
          />

          <Button 
            width={'200px'} 
            margin={'0 0 0 10px'} 
            hover
            onClick={()=>{
              setIsEdit(false)
              setTimeout(() => {
                setOpenEdit(true)
              }, 200);
            }}
          >
            Create snapshot
          </Button>
        </div>

      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <StyledTableHead >
            <TableRow >
              {columns.map((column) => (
                <StyledTableCell
                className={classes.head}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {snapshotsData?.map((row) => {
              return (
                <StyledTableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                     <StyledTableBodyCell >
                         {row.id}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell >
                         {row.name}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>
                        <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: 300 }}>
                          {row.tags?.map((value, index) => {
                              return(
                                  <Badge key={index}>
                                      {value}
                                  </Badge>
                              )
                          })}
                         </div>
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>
                        <StyledSwitch
                          checked={row.isActive}
                          onClick={()=>{
                            switchSnapshotStatus(row)
                          }}
                          color="primary"
                          name="checkedSnapshot"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      </StyledTableBodyCell>
                      <StyledTableBodyCell >
                        {
                          canDo(permissions.UPDATE_SNAPSHOT)  &&  <IconButton color="primary" aria-label="upload picture" component="span"
                              onClick={(e)=>{
                                setName(row.name)
                                setId(row.id)
                                set_Id(row._id)
                                setSelectedTags(row.tags)
                                setVideo(row.video)
                                setSetupGuide(row.setup_guilde)
                                setOverviewText(row.overview_text)
                                setImage(row.image)
                                setFacebookExample(row.facebook_example)
                                setIsEdit(true)
                                setTimeout(() => {
                                  setOpenEdit(true)
                                }, 200);
                              }}
                              >
                            <img src={EditIcon} alt='' />
                          </IconButton>
                        }
                        {
                          canDo(permissions.DELETE_SNAPSHOT)  &&  <IconButton style={{ marginLeft: 10 }} color="primary" aria-label="upload picture" component="span"
                              onClick={(e)=>{
                                setName(row.name)
                                set_Id(row._id)
                                setTimeout(() => {
                                  setOpenDelete(true)
                                }, 200);
                              }}
                              >
                            <img src={TrashIcon} alt='' style={{ width: 18 }} />
                          </IconButton>
                        }
                      </StyledTableBodyCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <StyledPagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {updateSnapshotModal()}
      {deleteSnapshotModal()}
      <Snackbar key="createAdmin" open={snackbarstatus.open}  message={snackbarstatus.message} state={snackbarstatus.state}></Snackbar>
    </>
  );
}
