import React, { useState, useEffect } from 'react'
import * as AgencyRouter from '../routing/agency.json'
import {http} from '../helpers/http'
import { useParams } from 'react-router'
import CustomCard from './customCard'
import Title from './Title'
import styled from 'styled-components'
import Grid2Icon from '../assets/images/grid2-icon.svg'
import Grid3Icon from '../assets/images/grid3-icon.svg'
import ListIcon from '../assets/images/list-icon.svg'
import Grid2IconW from '../assets/images/grid2-icon-white.svg'
import Grid3IconW from '../assets/images/grid3-icon-white.svg'
import ListIconW from '../assets/images/list-icon-white.svg'

const viewTypes = [
    {
        icon: Grid3Icon,
        iconWhite: Grid3IconW,
        size: '32%'
    },
    {
        icon: Grid2Icon,
        iconWhite: Grid2IconW,
        size: '48%'
    },
    {
        icon: ListIcon,
        iconWhite: ListIconW,
        size: '100%'
    }
]

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    .view-type {
        display: flex;
        align-items: center;
        border-radius: 10px;
        border: 2px solid #117D96;
    }
    .view-type>div {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
        border-radius: 8px;
        transition: 0.3s
    }
    .view-type>div img {
        width: 24px
    }
    .show-in-ghl {
        width: 150px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        border-radius: 8px;
        cursor: pointer;
        background: #117D96;
        color: #FFFFFF;
        font-weight: 700;
        text-decoration: none
    }
`

export default function Agency({agency}) {

    const [selectedViewType, setSelectedViewType] = useState(0)
    const _id = useParams()[0]

    const agenciesDataInit = []

    const fetchData = async() =>{
        const url = `${process.env.REACT_APP_API_BASE_URL}${AgencyRouter.GET_AGENCY}`
        const method = 'POST'
        const headers = {
            Authorization : `Bearer ${localStorage.getItem('token')}`
        }
        const body = {
            _id
        }
        let res = await http({url , method, headers, body})
        if(res?.response?.success){
            setagencyData(res.response.data)
        }
    }


    useEffect(() => {
        fetchData()
    }, [])

    const [agencyData, setagencyData] = useState(agency)

    let userData = []
    let contactData = []
    let locationData = []
    let parentLocationData = []
    let domainDataData = []
    let mailReachData = []


    if(agencyData && agencyData.users.length > 0 ){
        for(let i =0;  i  < agencyData.users.length; i++){
            let tmpObj = []
            for (const key in  agencyData.users[i]) {
                if(typeof agencyData.users[i][key] == 'object'){
                    for(const insideKey  in agencyData.users[i][key]){
                        tmpObj.push({key :  key + ' ' +insideKey, value :  agencyData.users[i][key][insideKey]});
                    }
                } else {
                    tmpObj.push({key : key, value :  agencyData.users[i][key]});
                }
            }
            userData.push(tmpObj)
        }
    }

    if(agencyData && agencyData.contact ){
        for (const key in  agencyData.contact) {
            if(typeof agencyData.contact[key] == 'object'){
                for(const insideKey  in agencyData.contact[key]){
                    contactData.push({key :  key + ' ' +insideKey, value :  agencyData.contact[key][insideKey]});
                }
            } else {
                contactData.push({key : key, value :  agencyData.contact[key]});
            }
        }
    }

    if(agencyData && agencyData.locations.length > 0 ){
        for(let i =0;  i  < agencyData.locations.length; i++){
            let tmpObj = []
            for (const key in  agencyData.locations[i]) {
                if(typeof agencyData.locations[i][key] == 'object'){
                    for(const insideKey  in agencyData.locations[i][key]){
                        tmpObj.push({key :  key + ' ' +insideKey, value :  agencyData.locations[i][key][insideKey]});
                    }
                } else {
                    tmpObj.push({key : key, value :  agencyData.locations[i][key]});
                }
            }
            locationData.push(tmpObj)
        }
    }

    if(agencyData && agencyData.parentLocation ){
        for (const key in  agencyData.parentLocation) {
            if(typeof agencyData.parentLocation[key] == 'object'){
                for(const insideKey  in agencyData.parentLocation[key]){
                    parentLocationData.push({key :  key + ' ' +insideKey, value :  agencyData.parentLocation[key][insideKey]});
                }
            } else {
                parentLocationData.push({key : key, value :  agencyData.parentLocation[key]});
            }
        }
    }
    

    if(agencyData && agencyData.mailreach ){
        for (const key in  agencyData.mailreach) {

            if(typeof agencyData.mailreach[key] == 'object'){

                for(const insideKey  in agencyData.mailreach[key]){
                    mailReachData.push({key :  key + ' ' +insideKey, value :  agencyData.mailreach[key][insideKey]});
                }

            } else {
                mailReachData.push({key : key, value :  agencyData.mailreach[key]});
            }

        }
    }

    if(agencyData && agencyData.domainData?.domain ){
        for (const key in  agencyData.domainData.domain) {
            domainDataData.push({key : key, value :  agencyData.domainData.domain[key]});
        }
    }

    const renderViewTypeIcon = (index) => {
        switch(index){
            case 1: {
                return <img src={selectedViewType === index ? Grid2IconW : Grid2Icon} alt='' />
            }
            case 2: {
                return <img src={selectedViewType === index ? ListIconW : ListIcon} alt='' />
            }
            case 0:
            default: {
                return <img src={selectedViewType === index ? Grid3IconW : Grid3Icon} alt='' />
            }
        }
    }

    return (
        <>
            <Header>
                <Title
                    title={'Agency'}
                />
                <>
                    <a href={`https://app.leadific.io/v2/location/${agencyData?.mainLocationId}`} target='_blank' rel="noreferrer" className='show-in-ghl'>
                        Show in GHL
                    </a>
                    <div className='view-type'>
                        {viewTypes.map((val, ind) => {
                            return (
                                <div 
                                    key={ind} 
                                    style={{ 
                                        background: selectedViewType === ind ? '#117D96' : '' 
                                    }}
                                    onClick={() => {
                                        setSelectedViewType(ind)
                                    }}
                                >
                                    {renderViewTypeIcon(ind)}
                                </div>
                            )
                        })}
                    </div>
                </>
            </Header>
            {
                agencyData ?
                    <div className="agencyData">
                        
                        {
                            userData.map((item, index)=>{
                                var username = item?.filter((val)=>val.key === 'name')[0]?.value
                                var userrole = item?.filter((val)=>val.key === 'roles role')[0]?.value
                                username = ': '+username+' - '+userrole
                                return(
                                    <CustomCard 
                                        width={viewTypes[selectedViewType].size} 
                                        title={'User'+(parseInt(index)+1)+username} 
                                        data={item}
                                    />
                                )
                            })
                        }
                        {
                            locationData.map((item, index)=>{

                                var title = item.map((val)=>{
                                    if(val.key === 'id'){
                                        if(val.value === agencyData?.mainLocationId){
                                            return 'Main location'
                                        }else{
                                            return 'Location '+(parseInt(index)+1)
                                        }
                                    } 
                                })

                                var locationName = item.filter((val)=>val.key === 'name')[0].value

                                var fullTitle = `${title}: ${locationName}`

                                return(
                                    <CustomCard 
                                        width={viewTypes[selectedViewType].size} 
                                        title={fullTitle.replaceAll(',', '')} 
                                        data={item}
                                    />
                                )
                            })
                        }
                        <CustomCard width={viewTypes[selectedViewType].size} title='Parent location' data={parentLocationData}></CustomCard>
                        <CustomCard width={viewTypes[selectedViewType].size} title='Contact' data={contactData}></CustomCard>
                        <CustomCard width={viewTypes[selectedViewType].size} title='Domain' data={domainDataData}></CustomCard>
                        {/* <CustomCard width={viewTypes[selectedViewType].size} title='Mail reach' data={mailReachData}></CustomCard> */}

                        {/* <iframe width="100%" height={800} src={`https://app.leadific.co`} title="W3Snchools Free Online Web Tutorials"></iframe> */}
                        
                    </div>
                    
                :
                    <div>Loading ...</div>
            }
        </>
    )
}
