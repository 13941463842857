import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import * as AgencyRouter from '../../routing/agency.json'
import Snackbar from '../snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import {http} from '../../helpers/http'
import { StyledDialogActions, StyledDialogContent, StyledDialogContentText, StyledDialogTitle, StyledModal } from './StyledModal';
import Button from '../Button';
import StyledInput from '../StyledInput';


export default function UpdateApiKeyModal({openApiKeyUpdateModal, hundleOpenApiKeyUpdateModal, fetchData, agencyId, apiKey}) {
  
  console.log('apiKey')
  console.log(apiKey)

  let initSnackBar = {
    open : false, message : null, state : 'error'
}

const [snackbarstatus, setsnackbarstatuss] = useState(initSnackBar)

  const [open, setOpen] = React.useState(false);
    const [isLoading, setisLoading] = useState(false)
    const [api_key, setapi_key] = useState(null)

  useEffect(() => {
    setOpen(openApiKeyUpdateModal)
  }, [openApiKeyUpdateModal])

  useEffect(() => {
    setapi_key(apiKey)
  }, [apiKey])

  const onSubmit =async(data) => {
    setisLoading(true)
    console.log('this is data')
    console.log(data)

    setsnackbarstatuss(initSnackBar)
    const url = `${process.env.REACT_APP_API_BASE_URL}${AgencyRouter.SYNC_API_KEY}`
    const method = 'POST'
    const body = {
        location_id :  api_key,
        agency_id : agencyId
    }
    const headers = {
        Authorization : `Bearer ${localStorage.getItem('token')}`,
    }
    let res = await http({url , method, headers, body})
    setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})

    if(res?.response?.success){
        handleClose()
        fetchData()
    }

    setisLoading(false)
    console.log(res)
  };


  const [selectedPermissions, setselectedPermissions] = useState([])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPersonName(value);
  };

  const handleClose = () => {
    hundleOpenApiKeyUpdateModal()
    setOpen(false);
  };

  const [personName, setPersonName] = React.useState([]);



  return (
    <div>
      <StyledModal open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
        <StyledDialogTitle id="form-dialog-title">Syncronise Api key of the agency</StyledDialogTitle>
        <StyledDialogContent>
          <form onSubmit={()=>onSubmit()}>
              <StyledDialogContentText>
                Api Key
              </StyledDialogContentText>
              <StyledInput
                autoFocus
                margin="dense"
                id="api_key"
                type="text"
                disabled
                name="api_key"
                defaultValue={apiKey}
                onChange={(e)=>setapi_key(e.target.value)}
                width ="fit"
                fullWidth
                variant='outlined'
                inputProps={{
                  style: {
                    height: 30,
                    borderRadius: 15
                  }
                }}
              />
          </form>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button 
            onClick={handleClose}
            background={'#FD658520'} 
            color="#FD6585"
            width={'120px'}
            height={'50px'}
            fontSize={'16px'}
          >
            Cancel
          </Button>
          {
            isLoading ?
              <CircularProgress  />
            :
              <Button 
                onClick={()=>onSubmit()}
                background="#26C49A1A" 
                width={'120px'}
                height={'50px'}
                color={'#26C49A'}
                fontSize={'16px'}
              >
                Syncronise
              </Button>
          }

        </StyledDialogActions>
      </StyledModal>
      <Snackbar key="zmar" open={snackbarstatus.open}  message={snackbarstatus.message} state={snackbarstatus.state}></Snackbar>
    </div>
  );
}
