import { Bar } from 'react-chartjs-2';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, useTheme, } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    root: {
      maxWidth: 345,
      textAlign : 'left',
      height: 'fit-content',
      boxShadow: 'none',
      borderRadius: 20
    },
    boldText: {
        fontWeight : 'bold'
    },
    pp : {
      overflowWrap: 'break-word'
    },
    title: {
      fontFamily: 'Rubik',
      fontWeight: '600',
      fontSize: 17,
      color: '#2A343F'
    }
  });

export default function BarChart({title,data, color}) {
    
    const classes = useStyles();

    const options={
      // maintainAspectRatio: false,
      cornerRadius: 8,
    }

    return (
      <Card 
        className={classes.root}
        style={{
          background: color?.secondary
        }}
      >
        <CardContent>
          <h2 className={classes.title}>
            {title}
          </h2>
          <Bar data={data} options={options} height={200} />
        </CardContent>
      </Card>
    )
}
