import styled from 'styled-components'

const Button = styled.button`
    width: ${props => props.width ? props.width : '100%'};
    height: ${props => props.height ? props.height : '60px'};
    background: ${props => props.background ? props.background : (props.disabled ? '#F9F9F9' : '#117D96')};
    color: ${props => props.color ? props.color : (props.disabled ? '#a5a5a5' : '#FFFFFF')};
    font-weight: 700;
    font-size: ${props => props.fontSize ? props.fontSize : '18px'};
    line-height: 30px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    opacity: ${props => props.opacity ? props.opacity : '1'};
    transition: 0.3s;
    &:hover {
        background: ${props => props.hover ? (props.disabled ? '' : '#26C49A') : ''}
    }
    margin: ${props => props.margin ? props.margin : ''}
`

export default Button