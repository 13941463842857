const getPermissions = () => {
    return {
        CREATE_ADMIN : "CREATE_ADMIN",
        READ_ADMIN : "READ_ADMIN",
        READ_AGENCY : "READ_AGENCY",
        UPDATE_ADMIN : "UPDATE_ADMIN",
        UPDATE_AGENCY_DOMAIN : "UPDATE_AGENCY_DOMAIN",
        DELETE_AGENCY : "DELETE_AGENCY",
        UPDATE_GHL_ADMIN : "UPDATE_GHL_ADMIN",
        GRANT_ADMIN_ACCESS : "GRANT_ADMIN_ACCESS",
        UPDATE_AGENCY  : "UPDATE_AGENCY",
        READ_STATS : "READ_STATS",
        ADD_LOCATION : "ADD_LOCATION",
        SYNC_USER_ACCESS : "SYNC_USER_ACCESS",
        UPDATE_MAIN_LOCATION_AGENCY : "UPDATE_MAIN_LOCATION_AGENCY",
        CREATE_SUB_LOCATION : "CREATE_SUB_LOCATION",
        CREATE_SUB_USER : "CREATE_SUB_USER",
        ADD_MAIL_REACH : "ADD_MAIL_REACH",
        UPGRADE_USER : "UPGRADE_USER",
        UPDATE_SNAPSHOT : "UPDATE_SNAPSHOT",
        READ_GHL_USER: "READ_GHL_USER",
        READ_SUBS: "READ_SUBS",
        READ_SNAPSHOT: "READ_SNAPSHOT",
        DELETE_SNAPSHOT: "DELETE_SNAPSHOT",
        CREATE_SNAPSHOT: "CREATE_SNAPSHOT"
    }
}

export default getPermissions