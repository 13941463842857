import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Stats from "../components/stats";
import getPermissions from "../permissions.ts";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import AgenciesDatatableC from "../components/agenciesDatatable";
import Agency from "../components/agency";
import AdminsDatatableC from "../components/adminsDatatable";
import { AuthContext } from "../contexts/auth.context";
import GhlUsersDatatableC from "../components/ghlUsersDatatable";
import NgAgenciesDatatable from "../components/ngAgenciesDatatable";
import SubAgenciesDatatable from "../components/subAgenciesDatatable";
import SnapshotsDatatable from "../components/snapshotsDatatable";

import LogoutIcon from '../assets/images/logout-icon.svg'
import Logo from '../assets/images/leadific.svg'
import LogoWhite from '../assets/images/leadific-white.svg'
import StatsIcon from '../assets/images/stats-icon.svg'
import StatsIconActive from '../assets/images/stats-icon-active.svg'
import SnapshotIcon from '../assets/images/snapshot-icon.svg'
import SnapshotIconActive from '../assets/images/snapshot-icon-active.svg'
import GHLUsersIcon from '../assets/images/ghl-users-icon.svg'
import GHLUsersIconActive from '../assets/images/ghl-users-icon-active.svg'
import AgencyIcon from '../assets/images/agencies-icon.svg'
import AgencyIconActive from '../assets/images/agencies-icon-active.svg'
import PaymentIcon from '../assets/images/payments-icon.svg'
import PaymentIconActive from '../assets/images/payments-icon-active.svg'
import UserIcon from '../assets/images/admins-icon.svg'
import UserIconActive from '../assets/images/admins-icon-active.svg'
import DrawerIcon from '../assets/images/drawer-arrow.svg'
import Icon from '../components/Icon'
import DrawerItem from "../components/DrawerItem";
import styled from 'styled-components'
import Title from "../components/Title";
import {useTheme} from 'styled-components'
import HomeView from "../components/homeView";

const StyledList = styled(List)`
  height: 100%;
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: ${props => props.isOpen ? '' : 'center'};
  padding: 0 15px;
  margin-top: -30px;
  a {
    text-decoration: none
  }
`

const drawerWidth = 260;


export default function MiniDrawer() {
  const styledTheme = useTheme();
  console.log({styledTheme})
  let authContext = useContext(AuthContext);

  const { logout, profile, canDo } = authContext;

  const permissions = getPermissions();

  const [actions, setactions] = useState([]);
  const [activeItem, setActiveItem] = useState("");

  useEffect(() => {
    switch(window.location.pathname){
      case '/admins': {
        return setActiveItem("admins")
      }
      case '/agencies': {
        return setActiveItem("agencies")
      }
      case '/sub/agencies': {
        return setActiveItem("sub/agencies")
      }      
      case '/ghlusers': {
        return setActiveItem("ghlusers")
      }
      case '/snapshots': {
        return setActiveItem("snapshots")
      }
      case '/stats': 
      default: {
        return setActiveItem("stats")
      }
    }
  },[])

  useEffect(() => {
    console.log("profile  changed");
    setactions([
      ...(canDo(permissions.READ_STATS)
        ? [
            { 
              name: "Stats", 
              route: "stats", 
            }
          ]
        : []),
      ...(canDo(permissions.READ_AGENCY)
        ? [
            {
              name: "Agencies",
              route: "agencies",
            },
          ]
        : []),
      ...(canDo(permissions.READ_SUBS) 
      ? [
          {
            name: "Subscriptions",
            route: "sub/agencies",
          },
        ]
      : []),
      ...(canDo(permissions.READ_ADMIN)
        ? [
            {
              name: "Admins",
              route: "admins",
            },
          ]
        : []),
      ...(canDo(permissions.READ_GHL_USER)
        ? [
            {
              name: "GHL users",
              route: "ghlusers",
            },
          ]
        : []),
      ...(canDo(permissions.READ_SNAPSHOT)
        ? [
            {
              name: "Snapshots",
              route: "snapshots",
            },
          ]
        : []),
    ]);
  }, [profile]);

  const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // height: "fit-content",
    backgroundColor: styledTheme.body,
    height: '100%',
  },
  appBar: {
    // justifyContent : 'space-between',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: 100000
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: styledTheme.body,
    boxShadow: `0px 0px 44px ${styledTheme.shadow}`,
    borderRadius: '0px 30px 30px 0px',
    border: 'none'
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
    background: styledTheme.body,
    boxShadow: `0px 0px 44px ${styledTheme.shadow}`,
    borderRadius: '0px 30px 30px 0px',
    border: 'none'
  },
  toolbar: {
    justifyContent : 'space-between',
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    marginBottom: 20,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  logo: {
    width: 200,
    marginLeft: -25
  },
  user: {
    minWidth: 200,
    height: 60,
    background: 'rgba(112, 192, 141, 0.1)',
    borderRadius: 20
  },
  userName: {
    fontWeight: '500',
    fontSize: 18,
    color: styledTheme.text,
    textAlign: 'center'
  },
  helloText: {
    color: '#26C49A'
  },
  toolbarItem: {
    flex: 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: styledTheme.body,
    height: '100%'
  },
  bottomPush: {
    textAlign: "center",
    padding: 10,
    width: '100%'
  },
}));

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = () => {
    setOpen(!open)
  }

  const renderIcon = (name) => {
    switch(name){
      case 'Stats': {
        return(
          <img src={activeItem === "stats" ? StatsIconActive : StatsIcon} alt='' />
        )
      }
      case 'Agencies': {
        return(
          <img src={activeItem === "agencies" ? AgencyIconActive : AgencyIcon} alt='' />
        )
      }
      case 'Subscriptions': {
        return(
          <img src={activeItem === "sub/agencies" ? PaymentIconActive : PaymentIcon} alt='' />
        )
      }
      case 'GHL users': {
        return(
          <img src={activeItem === "ghlusers" ? GHLUsersIconActive : GHLUsersIcon} alt='' />
        ) 
      }
      case 'Admins': {
        return(
          <img src={activeItem === "admins" ? UserIconActive : UserIcon} alt='' />
        )
      }
      case 'Snapshots': {
        return(
          <img src={activeItem === "snapshots" ? SnapshotIconActive : SnapshotIcon} alt='' />
        )
      }
      default: {
        return(
          <img src={StatsIcon} alt='' />
        )
      }
    }
  }

  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <Icon 
              onClick={toggleDrawer}
              img={DrawerIcon}
              margin={'50px 0'}
              imgStyled={{
                transform: !open ? 'rotate(180deg)' : '',
                width: 25
              }}
            />
          </div>

          <StyledList isOpen={open}>
            {actions.map((item, index) => (
              <Link 
                to={`/${item.route}`} 
                key={index}
                onClick={() => {setActiveItem(item.route)}}
              >
                <DrawerItem
                  icon={renderIcon(item.name)}
                  title={item.name}
                  isOpen={open}
                  isActive={item.route === activeItem}
                  onClick={() => {console.log('clicked')}}
                />
              </Link>
            ))}
            
          </StyledList>
          <div className={classes.bottomPush}>
              <DrawerItem
                icon={<img src={LogoutIcon} alt='' />}
                title={'Logout'}
                isOpen={open}
                isActive={true}
                background={'#117D96'}
                onClick={() => logout()}
              />
          </div>
        </Drawer>

        <main className={classes.content}>
          <div className={classes.toolbar}>
            <img src={styledTheme?.body === "#FEFFFE" ? Logo : LogoWhite} alt='' className={classes.logo} />

            <div className={classes.user}>
              <h3 className={classes.userName}>
                <span className={classes.helloText}>Hello, </span>{profile?.first_name}
              </h3>
            </div>
          </div>
          <Switch>
            <Route exact path="/">
              <HomeView actions={actions} userName={profile?.first_name} />
            </Route>
            <Route exact path="/agencies">
              <NgAgenciesDatatable/>
            </Route>
            <Route exact path="/sub/agencies">
              <SubAgenciesDatatable />
            </Route>
            <Route path="/agencies/*">
              <Agency></Agency>
            </Route>
            <Route path="/agenciesOld">
              <AgenciesDatatableC></AgenciesDatatableC>
            </Route>
            <Route path="/admins">
              <Title 
                title={'Administartors'}
              />
              <AdminsDatatableC></AdminsDatatableC>
            </Route>
            <Route path="/stats">
              <Title 
                title={'Statistics'}
              />
              <Stats></Stats>
            </Route>
            <Route path="/ghlusers">
              <Title 
                title={'GHL users'}
              />
              <GhlUsersDatatableC />
            </Route>
            <Route path="/snapshots">
              <Title 
                title={'Snapshots'}
              />
              <SnapshotsDatatable />
            </Route>
          </Switch>
        </main>
      </div>
    </Router>
  );
}
