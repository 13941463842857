import React, { useEffect, useState } from 'react';
import * as AgencyRouter from '../../routing/agency.json'
import Snackbar from '../snackbar';
import { CircularProgress } from '@mui/material';
import {http} from '../../helpers/http'
import { StyledDialogActions, StyledDialogContent, StyledDialogContentText, StyledDialogTitle, StyledModal } from './StyledModal';
import Button from '../Button';
import StyledInput from '../StyledInput';

export default function AddAgencyModal({openAddLocationModal, hundleOpenAddLocationModal, fetchData, agencyId}) {

  let initSnackBar = {
    open : false, message : null, state : 'error'
}

const [snackbarstatus, setsnackbarstatuss] = useState(initSnackBar)

  const [open, setOpen] = React.useState(false);
  const [location_id, setlocation_id] = useState(null)
  const [agency_id, setagency_id] = useState(null)
    const [isLoading, setisLoading] = useState(false)

  useEffect(() => {
    setOpen(openAddLocationModal)
  }, [openAddLocationModal])

  useEffect(() => {
    setagency_id(agencyId)
  }, [agencyId])


  const onSubmit =async(data) => {
    setisLoading(true)
    console.log('this is data')
    console.log(data)

    setsnackbarstatuss(initSnackBar)
    const url = `${process.env.REACT_APP_API_BASE_URL}${AgencyRouter.ADD_LOCATION}`
    const method = 'POST'
    const body = {location_id : location_id, agency_id : agency_id}
    const headers = {
        Authorization : `Bearer ${localStorage.getItem('token')}`,
    }
    let res = await http({url , method, headers, body})
    setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})

    if(res?.response?.success){
      handleClose()
      fetchData()
    }

    setisLoading(false)
    console.log(res)
  };

  useEffect(() => {
      setOpen(openAddLocationModal)
  }, [openAddLocationModal])

  const [selectedPermissions, setselectedPermissions] = useState([])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPersonName(value);
  };

  const handleClose = () => {
    hundleOpenAddLocationModal()
    setOpen(false);
  };

  const [personName, setPersonName] = React.useState([]);



  return (
    <div>
      <StyledModal open={open} onClose={handleClose} aria-labelledby="form-dialog-title" key="deleteAgencyDialog">
        <StyledDialogTitle>Add new location</StyledDialogTitle>
        <StyledDialogContent>
          <form onSubmit={()=>onSubmit()} koy="deleteForm">
            <StyledDialogContentText>
              Please enter a location id that you want to add to this agency.
            </StyledDialogContentText>
            <StyledInput
              autoFocus
              margin="dense"
              id="location_id"
              label="Location id"
              type="text"
              name="location_id"
              onChange={(e)=>{setlocation_id(e.target.value)}}
              fullWidth
              variant='outlined'
              inputProps={{
                style: {
                  height: 30,
                  borderRadius: 15
                }
              }}
            />
          </form>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button 
            onClick={handleClose} 
            background={'#FD658520'} 
            color="#FD6585"
            width={'120px'}
            height={'50px'}
            fontSize={'16px'}
          >
            Cancel
          </Button>
          {
            isLoading ?
              <CircularProgress  />
            :
              <Button 
                onClick={()=>onSubmit()} 
                background="#26C49A1A" 
                width={'120px'}
                height={'50px'}
                color={'#26C49A'}
                fontSize={'16px'}
              >
                Save
              </Button>
          }
        </StyledDialogActions>
      </StyledModal>
      <Snackbar key="deleteSnakBar" open={snackbarstatus.open}  message={snackbarstatus.message} state={snackbarstatus.state}></Snackbar>
    </div>
  );
}
