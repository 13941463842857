import { useState } from "react";
import AuthContextProvider from "./contexts/auth.context";
import FiltersContextProvider from "./contexts/filters.context"
import Root from './screens/root';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme, GlobalStyles } from './style/theme';
import DarkModeToggle from "react-dark-mode-toggle";

export default function App() {

  const [theme, setTheme] = useState("light");
  const [active, setActive] = useState(true);
  const isDarkTheme = theme === "dark";
  const toggleTheme = () => {
    setActive(!active)
    setTheme(isDarkTheme ? "light" : "dark")
  };

  return (
    <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
      <div className="theme-switch">
        <DarkModeToggle
          onChange={toggleTheme}
          checked={active}
          size={40}
          speed={1}
        />
      </div>
      <GlobalStyles />
      <AuthContextProvider>
        <FiltersContextProvider>
          <Root></Root>
        </FiltersContextProvider>
      </AuthContextProvider>
    </ThemeProvider>
  );
}