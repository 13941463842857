import styled from 'styled-components'

const Container = styled.div`
    width: 30px; height: 30px;
    max-width: 30px; max-height: 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    cursor: pointer;
    // background: red;
    img {
        width: 20px;
    }
`

function StyledMenuIcon(props){
    return(
        <Container>
            <img src={props.icon} alt='' />
        </Container>
    )
}

export default StyledMenuIcon