import React, { useEffect, useState } from 'react';
import * as AdminRouter from '../../routing/admin.json'
import Snackbar from '../snackbar';
import { CircularProgress } from '@mui/material';
import {http} from '../../helpers/http'
import { StyledDialogActions, StyledDialogContent, StyledDialogContentText, StyledDialogTitle, StyledModal } from './StyledModal';
import Button from '../Button';


export default function GrantGhlAdminAccessModal({openGhlGrantAdminAccess, hundleopenGhlGrantAdminAccess, fetchData, agencyId}) {

  let initSnackBar = {
    open : false, message : null, state : 'error'
}

const [snackbarstatus, setsnackbarstatuss] = useState(initSnackBar)

  const [open, setOpen] = React.useState(false);
    const [isLoading, setisLoading] = useState(false)

  useEffect(() => {
    setOpen(openGhlGrantAdminAccess)
  }, [openGhlGrantAdminAccess])


  const onSubmit =async(data) => {
    setisLoading(true)
    console.log('this is data')
    console.log(data)

    setsnackbarstatuss(initSnackBar)
    const url = `${process.env.REACT_APP_API_BASE_URL}${AdminRouter.GRANT_GHL_ADMIN_ACCESS}`
    const method = 'POST'
    const body = {agency_id : agencyId}
    const headers = {
        Authorization : `Bearer ${localStorage.getItem('token')}`,
    }
    let res = await http({url , method, headers, body})
    setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})

    if(res?.response?.success){
      handleClose()
      fetchData()
    }

    setisLoading(false)
    console.log(res)
  };

  useEffect(() => {
      setOpen(openGhlGrantAdminAccess)
  }, [openGhlGrantAdminAccess])

  const [selectedPermissions, setselectedPermissions] = useState([])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPersonName(value);
  };

  const handleClose = () => {
    hundleopenGhlGrantAdminAccess()
    setOpen(false);
  };

  const [personName, setPersonName] = React.useState([]);



  return (
    <div>
      <StyledModal open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <StyledDialogTitle>Admin access</StyledDialogTitle>
        <StyledDialogContent>
          <form onSubmit={(e)=>onSubmit()}>
            <StyledDialogContentText>
              Are you sure you want to grant your self admin access to this location, for 90 mins ?
            </StyledDialogContentText>
          </form>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button 
            onClick={handleClose}
            background={'#FD658520'} 
            color="#FD6585"
            width={'120px'}
            height={'50px'}
            fontSize={'16px'}
          >
            Cancel
          </Button>
          {
            isLoading ?
              <CircularProgress  />
            :
              <Button 
                onClick={(e)=>onSubmit()}
                background="#26C49A1A" 
                width={'120px'}
                height={'50px'}
                color={'#26C49A'}
                fontSize={'16px'}
              >
                Continue
              </Button>
          }
        </StyledDialogActions>
      </StyledModal>
      <Snackbar key="zmar" open={snackbarstatus.open}  message={snackbarstatus.message} state={snackbarstatus.state}></Snackbar>
    </div>
  );
}
