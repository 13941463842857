import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import * as UsersRouter from '../routing/user.json'
import {http} from '../helpers/http'
import Snackbar from '../components/snackbar'
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import Backdrop from '@material-ui/core/Backdrop';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinkIcon from '@material-ui/icons/Link';
import TextField from '@material-ui/core/TextField';
import {
    Link
} from "react-router-dom";
import CreateAdminModal from './modals/createAdminModal';
import Switch from '@material-ui/core/Switch';
import UpdateAdminModal from './modals/updateAdminModal';
import LinkGhlAdminModal from './modals/linkGhlAdminModal';
import {AuthContext} from '../contexts/auth.context'
import getPermissions from '../permissions.ts'
import StyledInput from './StyledInput';
import { StyledTableBodyCell, StyledTableRow } from './StyledTableBody';
import StyledPagination from './StyledPadination';
import { StyledTableCell, StyledTableHead } from './StyledTableHeader';
import { useTheme } from 'styled-components';


const columns = [
  { id: 'id', label: '_Id', minWidth: 170 },
  { id: 'first_name', label: 'first_name', minWidth: 100 },
  { id: 'last_name', label: 'last_name', minWidth: 100 },
  { id: 'email', label: 'email', minWidth: 100 },
  {
    id: 'Actions',
    label: 'Actions',
    // minWidth: 170,
    // align: 'right',
    format: (value) => value.toFixed(2),
  },
];


export default function GhlUsersDatatableC(props) {
  const styledTheme = useTheme()

  let initSnackBar = {
    open : false, message : null, state : 'error'
}

let searchTimer = null;

const [search, setsearch] = useState("")

const handleSearchChange = (evt) => {
  const val = evt.target.value;
  clearTimeout(searchTimer);
  searchTimer = setTimeout(() => {
      setsearch(val)
  }, 1000);
}
let authContext = useContext(AuthContext)

useEffect(() => {
 fetchData()
}, [search])

const {
  canDo
} = authContext


const permissions =  getPermissions()
const [snackbarstatus, setsnackbarstatuss] = useState(initSnackBar)
const [isLoading, setisLoading] = useState(false)

  const fetchData = async() =>{
    setisLoading(true)
    setsnackbarstatuss(initSnackBar)
      const url = `${process.env.REACT_APP_API_BASE_URL}${UsersRouter.ALL_USER}`
      const method = 'POST'
      let body = {
        filters : {
          search : search
        }
      }
      const headers = {
          Authorization : `Bearer ${localStorage.getItem('token')}`
      }
      let res = await http({url , method, headers, body})
      if(res?.response?.success){
          setadminsData(res.response.data)
      }
      setisLoading(false)
      var message = res.response?.message || (res.response?.success ? "Fetched data successfully" : "Something went wrong")
      var state = res.response?.success ? 'success' : 'error'
      setsnackbarstatuss({open:true , message : message, state : state})
      console.log({res})
  }

  const hundleOpenCreateModel = () => {
      setopenComand(!openComand)
  }

  const hundleOpenUpdateModel = () => {
    setopenUpdateComand(!openUpdateComand)
  }
    
  const hundleopenLinkGhlAdminModal = () => {
    setopenLinkGhlAdminModal(!openLinkGhlAdminModal)
  }
  

    const adminsDataInit = []
    const [adminsData, setadminsData] = useState(adminsDataInit)
    const [openComand, setopenComand] = useState(false)
    const [openUpdateComand, setopenUpdateComand] = useState(false)
    const [openLinkGhlAdminModal, setopenLinkGhlAdminModal] = useState(false)
    const [adminToUpdate, setadminToUpdate] = useState(null)

    useEffect(() => {
    fetchData()
    }, [])

const useStyles = makeStyles((theme)=>({
  head: {
    backgroundColor: "#3f51b54d",
    color:"black",
  },
  root: {
    width: '100%',
  },
  container: {
    borderRadius: '20px 20px 0 0',
    background: '#FFFFFF',
    boxShadow: `0px 0px 44px ${styledTheme.shadow}`,
    position: 'relative',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  addButton : {
  margin : '20px'
  },
  header : {
    backgroundColor : 'white',
    flex: 1,
    padding : '5px',
    marginBottom : '30px',
    borderRadius : '5px'
  },
  headerText : {
    fontWeight : 'bold',
    fontSize : '20px'
  },
  filters : {
    display: "flex",
    height: "fit-content",
    justifyContent: 'flex-end',
    width: '100%',
    position: 'relative',
    marginBottom: 30
  }
}));

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {/* <LinkGhlAdminModal
      openLinkGhlAdminModal={openLinkGhlAdminModal}
      fetchData={fetchData} 
      hundleopenLinkGhlAdminModal={hundleopenLinkGhlAdminModal}
      adminToUpdate={adminToUpdate}
      setadminToUpdate={setadminToUpdate}
      ></LinkGhlAdminModal> */}

          <Backdrop open={isLoading} className={classes.backdrop}>
          <CircularProgress color="primary" />
          </Backdrop>
        <CreateAdminModal fetchData={fetchData} openComand={openComand} hundleOpenCreateModel={hundleOpenCreateModel}></CreateAdminModal>
          
        <div className={classes.filters}>
          <StyledInput
            autoFocus
            style={{ width: 300}}
            id="Search"
            label="Search"
            type="text"
            margin="dense"
            variant='outlined'
            inputProps={{
              style: {
                height: 30,
                borderRadius: 15,
              }
            }}
            onChange={handleSearchChange}
          />
        </div>

      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <StyledTableHead >
            <TableRow >
              {columns.map((column) => (
                <StyledTableCell
                className={classes.head}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {adminsData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <StyledTableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                     <StyledTableBodyCell >
                         {row.id}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell >
                         {row.firstName}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell >
                         {row.lastName}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell >
                         {row.email}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell >
              {/* {
                canDo(permissions.UPDATE_ADMIN)  &&   <IconButton  IconButton color="primary" aria-label="upload picture" component="span"
                            onClick={(e)=>{
                              setadminToUpdate(row)
                              hundleOpenUpdateModel()
                            }}
                            >
                          <EditRoundedIcon />
                        </IconButton>
                }

                {
                canDo(permissions.UPDATE_GHL_ADMIN)  && <IconButton color="primary" aria-label="upload picture" component="span"
                            onClick={(e)=>{
                              setadminToUpdate(row)
                              hundleopenLinkGhlAdminModal()
                            }}
                            >
                          <LinkIcon />
                        </IconButton>
                  } */}
                      </StyledTableBodyCell>

                  {/* {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })} */}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

        <StyledPagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={adminsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    <Snackbar key="createAdmin" open={snackbarstatus.open}  message={snackbarstatus.message} state={snackbarstatus.state}></Snackbar>
    </>
  );
}
