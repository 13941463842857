import React, { useEffect, useState } from "react";
import * as AdminRouter from "../../routing/agency.json";
import Snackbar from "../snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { http } from "../../helpers/http";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { StyledDialogActions, StyledDialogContent, StyledDialogContentText, StyledDialogTitle, StyledModal } from "./StyledModal";
import Button from "../Button";
import StyledSelect from "../StyledSelect";
import { FormControl } from "@material-ui/core";

export default function UpdateSnapShotModal({
  openUpdateSnapShotModal,
  hundleopenUpdateSnapShotModal,
  fetchData,
  locations,
}) {
  let initSnackBar = {
    open: false,
    message: null,
    state: "error",
  };

  const [snackbarstatus, setsnackbarstatuss] = useState(initSnackBar);

  const [open, setOpen] = React.useState(false);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedSnapshot, setSelectedSnapshot] = useState(null);
  const [locationsList, setlocationsList] = useState([]);
  const [snapshotsList, setSnapshotsList] = useState([]);

  useEffect(() => {
    if (locations && locations.length > 0) setlocationsList(locations);
  }, [locations]);

  useEffect(() => {
    setOpen(openUpdateSnapShotModal);
  }, [openUpdateSnapShotModal]);

  const onSubmit = async () => {

    setisLoading(true);

    setsnackbarstatuss(initSnackBar);
    const url = `${process.env.REACT_APP_API_BASE_URL}${AdminRouter.UPDATE_SNAPSHOT}`;
    const method = "POST";
    const body = {
      locationId: selectedLocation.id,
      snapshotId: selectedSnapshot.id
    };
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    let res = await http({ url, method, headers, body });

    setsnackbarstatuss({
      open: true,
      message: res.response?.message,
      state: res.response?.success ? "success" : "error",
    });

    if (res?.response?.success) {
      handleClose();
      setOpenConfirmation(false);
      fetchData();
    }

    setisLoading(false);
  };

  const getSnapshotsList = async () => {
    const url = `${process.env.REACT_APP_API_BASE_URL}${AdminRouter.GET_SNAPSHOTS}`;
    const method = "POST";
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    let res = await http({ url, method, headers });
    console.log({res})
    if(res.status){
      setSnapshotsList(res?.response?.data?.list)
    }
  }

  useEffect(() => {
    getSnapshotsList()
  }, [])
  

  useEffect(() => {
    setOpen(openUpdateSnapShotModal);
  }, [openUpdateSnapShotModal]);

  const handleClose = () => {
    hundleopenUpdateSnapShotModal();
    setOpen(false);
  };

  const confirmationModal = () => {
    return(
      <StyledModal
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        aria-labelledby="form-dialog-confirmation"
      >
        <StyledDialogTitle>
          Confirmation!
        </StyledDialogTitle>
        <StyledDialogContent style={{ maxWidth: 450 }}>
          <StyledDialogContentText>
            Are you sure you want to update 
            <span style={{ color: '#2A343F', fontWeight: '500' }}>{' '+selectedLocation?.name+' '}</span> 
            snapshot to 
            <span style={{ color: '#2A343F', fontWeight: '500' }}>{' '+selectedSnapshot?.name} ?</span>
          </StyledDialogContentText>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button 
            onClick={() => setOpenConfirmation(false)}
            background={'#FD658520'} 
            color="#FD6585"
            width={'120px'}
            height={'50px'}
            fontSize={'16px'}
          >
            Cancel
          </Button>
          <Button 
            onClick={() => !isLoading && onSubmit()}
            background="#26C49A1A" 
            width={'120px'}
            height={'50px'}
            color={'#26C49A'}
            fontSize={'16px'}
            opacity={isLoading ? '0.7' : '1'}
          >
            {isLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress color="inherit" size={24} />
              </div>
            ) : ("Confirm")}
          </Button>
        </StyledDialogActions>
      </StyledModal>
    )
  }

  return (
    <div>
      <StyledModal
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <StyledDialogTitle>
          Are you sure you want to update the SNAPSHOT ?
        </StyledDialogTitle>
        <StyledDialogContent>
          <form onSubmit={()=>onSubmit()}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-label" style={{ marginRight: 10, fontFamily: 'Rubik' }}>
                  Select the location
                </InputLabel>
                <StyledSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedLocation}
                  onChange={(e) => setSelectedLocation(e.target.value)}
                >
                  {locationsList.map((item) => (
                    <MenuItem value={item || undefined}>{item.name}</MenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel id="simple-select-label" style={{ marginRight: 10, fontFamily: 'Rubik' }}>
                  Select a snapshot
                </InputLabel>
                <StyledSelect
                  labelId="simple-select-label"
                  id="simple-select"
                  value={selectedSnapshot}
                  onChange={(e) => setSelectedSnapshot(e.target.value)}
                >
                  {snapshotsList.map((item) => (
                    <MenuItem value={item || undefined}>{item.name}</MenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
            </div>
          </form>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button 
            onClick={handleClose}
            background={'#FD658520'} 
            color="#FD6585"
            width={'120px'}
            height={'50px'}
            fontSize={'16px'}
          >
            Cancel
          </Button>
          <Button 
            onClick={() => {
              if(selectedLocation && selectedSnapshot){
                setOpenConfirmation(true)
              }
            }}
            background="#26C49A1A" 
            width={'120px'}
            height={'50px'}
            color={'#26C49A'}
            fontSize={'16px'}
            opacity={(selectedLocation && selectedSnapshot) ? '1' : '0.7'}
          >
            Yes
          </Button>
        </StyledDialogActions>
      </StyledModal>
      {confirmationModal()}

      <Snackbar 
        key="updateSnapshot" 
        open={snackbarstatus.open}  
        message={snackbarstatus.message} 
        state={snackbarstatus.state}
      />
    </div>
  );
}
