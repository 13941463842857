import styled from 'styled-components'
import CheckIcon from '../assets/images/check-icon-white.svg'

const Checkbox = styled.div`
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 8px;
    background: ${props => props.checked ? '#26C49A' : '#a5a5a5'};
    display: flex;
    align-items: center;
    justify-content: center;
`

function StyledCheckbox(props) {
    return (
        <Checkbox checked={props.checked}>
            <img src={CheckIcon} alt='' />
        </Checkbox>
    )
}

export {StyledCheckbox}