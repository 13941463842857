import React, { useEffect, useState, useContext } from 'react';
import { InputLabel, MenuItem, FormControl, Chip, Input, Dialog } from '@mui/material';
import * as AdminRouter from '../../routing/admin.json'
import { useForm } from 'react-hook-form';
import Snackbar from '../snackbar';
import {AuthContext} from '../../contexts/auth.context'
import {http} from '../../helpers/http'
import { StyledDialogActions, StyledDialogContent, StyledDialogContentText, StyledDialogTitle, StyledModal } from './StyledModal';
import Button from '../Button';
import StyledInput from '../StyledInput';
import StyledSelect from '../StyledSelect';
import {useTheme} from 'styled-components'

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });
let roles = []
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


export default function CreateAdminModal({openComand, hundleOpenCreateModel, fetchData}) {

  const styledTheme = useTheme()
  let initSnackBar = {
    open : false, message : null, state : 'error'
}

let authContext = useContext(AuthContext)

let {profile} = authContext

if(profile){
  roles = profile.permissions
}

const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        background: styledTheme.body2,
        color: styledTheme.text,
      },
    },
  };

const [snackbarstatus, setsnackbarstatuss] = useState(initSnackBar)

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(openComand)
  }, [openComand])

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit =async(data) => {
    console.log('this is data')
    console.log(data)

    setsnackbarstatuss(initSnackBar)
    const url = `${process.env.REACT_APP_API_BASE_URL}${AdminRouter.ADD_ADMIN}`
    const method = 'POST'
    const body = data
    const headers = {
        Authorization : `Bearer ${localStorage.getItem('token')}`
    }
    let res = await http({url , method, headers, body})
    setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})

    if(res?.response?.success){
      reset()
      handleClose()
      fetchData()
    }

    console.log(res)
  };

  useEffect(() => {
      setOpen(openComand)
  }, [openComand])

  const [selectedPermissions, setselectedPermissions] = useState([])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPersonName(value);
  };

  const handleClose = () => {
    hundleOpenCreateModel()
    setOpen(false);
  };

  const [personName, setPersonName] = React.useState([]);



  return (
    <div>
      <StyledModal open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <StyledDialogTitle>Create Admin</StyledDialogTitle>
        <StyledDialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <StyledDialogContentText>
              To add a new admin, please enter your following informations.
            </StyledDialogContentText>
            <StyledInput
              autoFocus
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              name="email"
              {...register('email')}
              fullWidth
              variant='outlined'
              inputProps={{
                style: {
                  // height: 30,
                  // borderRadius: 15
                }
              }}
            />
            <StyledInput
              autoFocus
              margin="dense"
              id="first_name"
              {...register('first_name')}
              label="First Name"
              type="text"
              fullWidth
              variant='outlined'
              inputProps={{
                style: {
                  // height: 30,
                  // borderRadius: 15
                }
              }}
            />
            <StyledInput
              autoFocus
              margin="dense"
              id="last_name"
              {...register('last_name')}
              label="Last Name"
              type="text"
              fullWidth
              variant='outlined'
              inputProps={{
                style: {
                  // height: 30,
                  // borderRadius: 15
                }
              }}
            />
            <StyledInput
              autoFocus
              margin="dense"
              id="password"
              {...register('password')}
              label="Password"
              type="text"
              fullWidth
              variant='outlined'
              inputProps={{
                style: {
                  // height: 30,
                  // borderRadius: 15
                }
              }}
            />
            <FormControl variant="outlined" fullWidth style={{ marginTop: 10 }}>
              <InputLabel id="demo-mutiple-chip-label" fullWidth style={{color: styledTheme.text, top: -5, opacity: 0.7}}>Permissions</InputLabel>
                <StyledSelect
                  fullWidth
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  // {...register('permissions')}
                  inputProps={register('permissions')}
                  enforceFocus={false}
                  value={personName}
                  onChange={handleChange}
                  // input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div>
                      {selected.map((value) => (
                        <Chip key={'selected'+value} label={value} />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {roles.map((name, index) => (
                    <MenuItem key={'roles'+index+name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </StyledSelect>
            </FormControl>
          </form>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button 
            onClick={handleClose} 
            background={'#FD658520'} 
            color="#FD6585"
            width={'120px'}
            height={'50px'}
            fontSize={'16px'}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit(onSubmit)} 
            background="#26C49A1A" 
            width={'120px'}
            height={'50px'}
            color={'#26C49A'}
            fontSize={'16px'}
          >
            Save
          </Button>
        </StyledDialogActions>
      </StyledModal>
      <Snackbar key="zmar" open={snackbarstatus.open}  message={snackbarstatus.message} state={snackbarstatus.state}></Snackbar>
    </div>
  );
}
