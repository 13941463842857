import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import * as AgencyRouter from '../routing/agency.json'
import {http} from '../helpers/http'
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import Snackbar from '../components/snackbar'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DomainIcon from '@material-ui/icons/Domain';
import DeleteIcon from '@material-ui/icons/Delete';
import ChangeDomainModal from './modals/changeDomainModal'
import DeleteAgencyModal from './modals/deleteAgencyModal'
import GrantGhlAdminAccessModal from './modals/ghlAdminAccessModal'
import LockOpenIcon from '@material-ui/icons/LockOpen';
import SyncIcon from '@material-ui/icons/Sync';
import Tooltip from '@material-ui/core/Tooltip';
import {AuthContext} from '../contexts/auth.context'
import {FiltersContext} from '../contexts/filters.context'
import getPermissions from '../permissions.ts'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddAgencyModal from './modals/addLocationModal'
import UpdateMainLocationModal from './modals/updateMainLocationModal'
import LooksOneIcon from '@material-ui/icons/LooksOne';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import {useTheme} from 'styled-components'

import {
    Link
} from "react-router-dom";
import StyledInput from './StyledInput';

const columns = [
  { id: 'main  location', label: 'main'},
  { id: 'Domain', label: 'Domain' },
  {
    id: 'Full name',
    label: 'Full name',
    // minWidth: 170,
    // align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'Email',
    label: 'Email',
    // minWidth: 170,
    // align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'Phone',
    label: 'Phone',
    // minWidth: 170,
    // align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'contact_id',
    label: 'Contact id',
    // minWidth: 170,
    // align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'apiKey',
    label: 'Api Key',
    // minWidth: 170,
    // align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'Actions',
    label: 'Actions',
    minWidth: 250,
    // align: 'right',
    format: (value) => value.toFixed(2),
  },
];

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export default function AgenciesDatatableC(props) {

  let authContext = useContext(AuthContext)
  let filterContext = useContext(FiltersContext)

  const {
    canDo
  } = authContext

  const tags = [
    "onboarding",
    "subscribed",
    "express account",
    "monthly",
    "dfy",
    "dwy",
    "mm",
    "onboarding-form-complete"
  ]

  const {
    states
  } = filterContext

  let initSnackBar = {
    open : false, message : null, state : 'error'
}
const [openChangeDomainModal, setopenChangeDomainModal] = useState(false)
const [openDeleteAgencyModal, setopenDeleteAgencyModal] = useState(false)
const [openAddLocationModal, setopenAddLocationModal] = useState(false)
const [openMainLocationUpdateModal, setopenMainLocationUpdateModal] = useState(false)
const [openGhlGrantAdminAccess, setopenGhlGrantAdminAccess] = useState(false)
const [snackbarstatus, setsnackbarstatuss] = useState(initSnackBar)
const [contactId, setcontactId] = useState(null)
const [agencyId, setagencyId] = useState(null)
const [selectedStates, setselectedStates] = useState([])
const [selectedTag, setselectedTag] = useState(null)
const [mainLocation, setmainLocation] = useState("")
const [search, setsearch] = useState("")
const styledTheme = useTheme();

const useStyles = makeStyles((theme)=>({
  head: {
    backgroundColor: "#3f51b54d",
    color:"black",
  },
  root: {
    width: '100%',
    flexGrow: 1,
  },
  container: {
    maxHeight: '80vh',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  header : {
    backgroundColor : 'white',
    flex: 1,
    padding : '5px',
    marginBottom : '30px',
    borderRadius : '5px'
  },
  headerText : {
    fontWeight : 'bold',
    fontSize : '20px'
  },
  filters : {
    display : 'flex',
    height: 'fit-content',
    minHeight : '80px',
  }
}));

let searchTimer = null;

const handleSearchChange = (evt) => {
  const val = evt.target.value;
  clearTimeout(searchTimer);
  searchTimer = setTimeout(() => {
      setsearch(val)
  }, 1000);
}

useEffect(() => {
  fetchData()
}, [selectedStates, selectedTag])

useEffect(() => {
  console.log('search')
  console.log(search)
  fetchData()
}, [search])

const hundleOpenChangeDomainModel = (contact_id ) => {
  setcontactId(contact_id)
  setopenChangeDomainModal(!openChangeDomainModal)
}

const hundleopenGhlGrantAdminAccess = (agency_id ) => {
  setagencyId(agency_id)
  setopenGhlGrantAdminAccess(!openGhlGrantAdminAccess)
}

const hundleOpenDeleteAgencyModal = (contact_id , agency_id) => {
  setcontactId(contact_id)
  setagencyId(agency_id)
  setopenDeleteAgencyModal(!openDeleteAgencyModal)
}

  const hundleOpenAddLocationModal = (agency_id) => {
    setagencyId(agency_id)
    setopenAddLocationModal(!openAddLocationModal)
  }

  const hundleOpenMainLocationUpdateModal = (agency_id,  main_location) => {
    setagencyId(agency_id)
    setmainLocation(main_location)
    setopenMainLocationUpdateModal(!openMainLocationUpdateModal)
  }

    const syncAgency = async(agency_id) => {
      setsnackbarstatuss({    open:false , message : '', state :  'success'})
      setisLoading(true)
      const url = `${process.env.REACT_APP_API_BASE_URL}${AgencyRouter.SYNC_AGENCY}`
      const method = 'POST'
      const headers = {
          Authorization : `Bearer ${localStorage.getItem('token')}`
      }
      const body = {agency_id}
      let res = await http({url , method, headers, body})
      console.log(res)
      setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})
      setisLoading(false)
    }

const createSubUser = async(agency_id) => {
  setsnackbarstatuss({    open:false , message : '', state :  'success'})
  setisLoading(true)
  const url = `${process.env.REACT_APP_API_BASE_URL}${AgencyRouter.CREATE_SUB_USER}`
  const method = 'POST'
  const headers = {
      Authorization : `Bearer ${localStorage.getItem('token')}`
  }
  const body = {agency_id}
  let res = await http({url , method, headers, body})
  console.log(res)
  setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})
  setisLoading(false)
}

    const createSubLocation = async(agency_id) => {
      setsnackbarstatuss({    open:false , message : '', state :  'success'})
      setisLoading(true)
      const url = `${process.env.REACT_APP_API_BASE_URL}${AgencyRouter.CREATE_SUB_LOCATION}`
      const method = 'POST'
      const headers = {
          Authorization : `Bearer ${localStorage.getItem('token')}`
      }
      const body = {agency_id}
      let res = await http({url , method, headers, body})
      console.log(res)
      setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})
      setisLoading(false)
    }

    const syncAgencyUserAccess = async(agency_id) => {
      setsnackbarstatuss({    open:false , message : '', state :  'success'})
      setisLoading(true)
      const url = `${process.env.REACT_APP_API_BASE_URL}${AgencyRouter.SYNC_USER_ACESS}`
      const method = 'POST'
      const headers = {
          Authorization : `Bearer ${localStorage.getItem('token')}`
      }
      const body = {agency_id}
      let res = await http({url , method, headers, body})
      console.log(res)
      setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})
      setisLoading(false)
    }    

    const fetchData = async() =>{
      setisLoading(true)
        const url = `${process.env.REACT_APP_API_BASE_URL}${AgencyRouter.ALL_AGENCIES}`
        const method = 'POST'
        const body = {
          filters : {
            states :  selectedStates,
            search : search,
            tags : selectedTag
          }
        }
        const headers = {
            Authorization : `Bearer ${localStorage.getItem('token')}`
        }
        let res = await http({url , method, headers, body})
        if(res?.response?.success){
            setagenciesData(res.response.data)
        }
        setsnackbarstatuss({    open:true , message : res.response?.message, state : res.response?.success ? 'success' : 'error'})
        setisLoading(false)
        console.log(res)
    }

    const agenciesDataInit = []
    const [agenciesData, setagenciesData] = useState(agenciesDataInit)

    useEffect(() => {
    fetchData()
    }, [])

  const permissions =  getPermissions()
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isLoading, setisLoading] = useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <div className={classes.header}>
        <p className={classes.headerText} >
        Agencies
        </p>
      </div>

      <UpdateMainLocationModal
        hundleOpenMainLocationUpdateModal={hundleOpenMainLocationUpdateModal}
        openMainLocationUpdateModal={openMainLocationUpdateModal} 
        agencyId={agencyId}
        fetchData={fetchData}
        mainLocation={mainLocation}
      > 
      </UpdateMainLocationModal>

      <GrantGhlAdminAccessModal
      hundleopenGhlGrantAdminAccess={hundleopenGhlGrantAdminAccess}
      openGhlGrantAdminAccess={openGhlGrantAdminAccess} 
      agencyId={agencyId}
      fetchData={fetchData}
      >
      </GrantGhlAdminAccessModal>

      <ChangeDomainModal
      openChangeDomainModal={openChangeDomainModal} 
      hundleOpenChangeDomainModel={hundleOpenChangeDomainModel} 
      contactId={contactId} 
      fetchData={fetchData} 
      ></ChangeDomainModal>

      <AddAgencyModal
        openAddLocationModal={openAddLocationModal} 
        hundleOpenAddLocationModal={hundleOpenAddLocationModal} 
        agencyId={agencyId} 
        fetchData={fetchData} 
      >
      </AddAgencyModal>
      
      <DeleteAgencyModal 
        openDeleteAgencyModal={openDeleteAgencyModal}
        hundleOpenDeleteAgencyModal = {hundleOpenDeleteAgencyModal}
        agencyId={agencyId}
        fetchData={fetchData} 
      ></DeleteAgencyModal>
      
      <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Paper className={classes.root}>
        <div className={classes.filters}>
          <StyledInput
            autoFocus
            style={{ width: 300, height: '40px', marginLeft : '10px', marginTop : '10px'}}
            id="Search"
            label="Search"
            type="text"
            margin="dense"
            onChange={handleSearchChange}
            variant='outlined'
            inputProps={{
              style: {
                height: 30,
                borderRadius: 15
              }
            }}
          />
          <Autocomplete
            id="state-select"
            style={{ width: 300, paddingTop: '10px', paddingBottom : '10px', marginLeft : '10px'}}
            options={states}
            autoHighlight
            onChange={(event, value)=>{setselectedStates(value ? value?.abbreviation : [])}}
            getOptionLabel={(option) => option.name}
            renderOption={(option) => (
              <React.Fragment value={option.abbreviation}>
                <span>{option.abbreviation} - </span>
                {option.name}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <StyledInput
                {...params}
                label="Choose a state"
                variant='outlined'        
                margin="dense" 
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                  style: {
                    height: 30,
                    borderRadius: 15
                  }
                }}
              />
            )}
          />

          <Autocomplete
            id="state-select"
            style={{ width: 300, paddingTop: '10px', paddingBottom : '10px', marginLeft : '10px'}}
            options={tags}
            autoHighlight
            onChange={(event, value)=>{setselectedTag(value ? value : null)}}
            getOptionLabel={(option) => option}
            renderOption={(option) => (
              <React.Fragment value={option}>
                <span>{option}</span>
              </React.Fragment>
            )}
            renderInput={(params) => (
              <StyledInput
                {...params}
                label="Choose a tag"
                variant="outlined"
                margin="dense"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                  style: {
                    height: 30,
                    borderRadius: 15
                  }
                }}
              />
            )}
          />
        </div>

        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                  className={classes.head}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {agenciesData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                console.log(row)
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                      <TableCell >
                          {row.mainLocationId}
                        </TableCell>
                        <TableCell >
                          {row.AgencyDomain}
                        </TableCell>
                        <TableCell >
                          {row.full_name}
                        </TableCell>
                        <TableCell >
                          {row.email}
                        </TableCell>
                        <TableCell >
                          {row.phone}
                        </TableCell>
                        <TableCell >
                          {row.contact_id}
                        </TableCell>
                        <TableCell >
                          {row.agencyApiKey}
                        </TableCell>
                        <TableCell >

                        </TableCell>

                    {/* {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })} */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={agenciesData.length}
          defaultValue={5}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <Snackbar key="xxx" open={snackbarstatus.open}  message={snackbarstatus.message} state={snackbarstatus.state}></Snackbar>
      </Paper>
    </>
  );
}
