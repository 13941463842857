import React, { useContext} from 'react'
import Login from "../screens/login";
import Home from "../screens/home"
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import {AuthContext} from "../contexts/auth.context";

export default function Root() {

    let authContext = useContext(AuthContext)

    const {
        isAuth
        } = authContext

    return (
            <Router>
            <div className="App">
                {/* <nav>
                    <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/about">About</Link>
                    </li>
                    <li>
                        <Link to="/users">Users</Link>
                    </li>
                    </ul>
                </nav> */}
        
                {/* A <Switch> looks through its children <Route>s and
                    renders the first one that matches the current URL. */}
                <Switch>
                    <Route path="/login">
                        <Login />
                    </Route>
                    {
                        !isAuth ? <Login /> :  <Home></Home>
                    }
                    
                </Switch>
                {/*<div className="footer">© 2021 Leadific</div>*/}
            </div>
        </Router>
    )
}
