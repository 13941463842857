import styled from 'styled-components'

const Container = styled.div`
    width: ${props => props.width ? props.width : '50px'};
    height: ${props => props.height ? props.height : '50px'};
    min-height: 50px; min-width: 50px;
    background: ${props => props.disabled ? '#F9F9F9' : '#117D96'};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;
    margin: ${props => props.margin ? props.margin : ''};
    transition: 0.3s;
    &:hover {
        background: #26C49A
    }
`

function Button({img, imgStyled, width, height, disabled, onClick, margin}) {
    return(
        <Container onClick={() => onClick()} width={width} height={height} disabled={disabled} margin={margin}>
            <img 
                src={img}
                style={imgStyled}
                alt=''
            />
        </Container>
    )
}

export default Button
