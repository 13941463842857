import styled from 'styled-components'
import HomeIllu from '../assets/images/home-illu.svg'
import {useHistory} from 'react-router-dom'

const Container = styled.div`
    display: flex;
    align-items: center;
    background: ${({theme}) => theme.body2};
    border-radius: 15px;
    .illu {
        width: 100%;
    }
    .content {
        width: 100%;
        padding: 30px 100px;
        text-align: left;
    }
    .content h2 {
        font-size: 30px
    }
    .content h2 span {
        color: ${({theme}) => theme.green}
    }
    .goto {
        font-size: 18px;
    }
    .quick-access-menu {
        text-align: center;
        display: flex;
        // flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 20px;
    }
    .item {
        background: ${({theme}) => theme.grey};
        padding: 20px;
        border-radius: 15px;
        margin-bottom: 10px;
        margin-left: 10px;
        cursor: pointer;
        width: 250px;
        transition: 0.3s
    }
    .item h4 {
        margin: 0;
        font-size: 20px;
        transition: 0.3s
    }
    .item:hover {
        background: ${({theme}) => theme.green};
    }
    .item:hover h4 {
        color: #ffffff !important;
    }
`

function HomeView({actions, userName}) {
    const route = useHistory() 
    return(
        <Container>
            <div className='illu'>
                <img src={HomeIllu} alt=''/>
            </div>
            <div className='content'>
                <h2>
                    Welcome <span>{userName}</span>
                </h2>
                <span className='goto'>
                    Go to
                </span>
                <div className='quick-access-menu'>
                {
                    actions?.map((value, index) => {
                        return(
                            <div onClick={() => route.push(value?.route)} className='item' key={index}>
                                <h4>
                                    {value.name}
                                </h4>
                            </div>
                        )
                    })
                }
                </div>
            </div>
        </Container>
    )
}

export default HomeView
