const axios = require( 'axios' )


const http = async ( {headers = {}, body = null, url = '', method = 'get'} ) => {
    console.log({body})
try {
    let response = await axios( {
    headers: headers,
    method: method,
    url: url,
    data: body
    } )
console.log({response})
    return { status: true, response: response?.data, statusCode :  response?.response?.status}
}
catch ( err ) { 
    console.log( 'ERROR' )
    console.log(err?.response)

    console.log( err.toJSON() )
    console.log(err?.response?.data)

    return { status: false, response: err?.response?.data, statusCode : err?.response?.status}


}

}

export  {http}